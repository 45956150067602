import { Container, interfaces } from 'inversify';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

interface InversifyContextType {
	container: Container;
}

const InversifyContext = createContext<InversifyContextType | undefined>(
	undefined,
);

type Props = {
	children: ReactNode;
	container: Container;
};

export const InversifyProvider: React.FC<Props> = ({ container, children }) => {
	return (
		<InversifyContext.Provider value={{ container: container }}>
			{children}
		</InversifyContext.Provider>
	);
};

export function useInjection<T>(
	identifier: interfaces.ServiceIdentifier<T>,
): T {
	const { container } = useContext(InversifyContext);
	if (!container) {
		throw new Error();
	}
	return useMemo(() => container.get<T>(identifier), [container, identifier]);
}

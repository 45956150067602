import React, { FunctionComponent, useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ColumnGroup } from './Models';
import TableColumnGroup from './TableColumnGroup';
import TableFooter from './TableFooter';
import { createColumnGroup } from './TableUtils';

interface ColumnGroupTableProps {
  columngroups: ColumnGroup[],
  isSortedAscending: boolean,
  sortedByColumn: any,
  hidePageInputs?: boolean,
  hideFooter?: boolean,
  maxColumns?: number,
  navigateHandler?: (num: number) => void,
  translations?: Record<string, string>
}

const ColumnGroupTable: FunctionComponent<ColumnGroupTableProps> = ({
  columngroups,
  isSortedAscending,
  sortedByColumn,
  hidePageInputs,
  hideFooter,
  maxColumns,
  navigateHandler,
  translations
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(10);

  const onChangeNumberOfItemsPerPage = (numberOfItems: number) => {
    setNumberOfItemsPerPage(numberOfItems);
  };

  const onChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (navigateHandler)
      navigateHandler(pageNumber);
  };

  const { currentSize: currentScreenSize } = useWindowDimensions();
  const getNumberOfTotalColumnsToBeDisplayed = (screenSize: string) => {
    switch (screenSize) {
      case 'xs':
        return 1;
      case 'sm':
        return 1;
      case 'md':
        return maxColumns;
      case 'lg':
        return maxColumns;
      case 'xl':
        return maxColumns;
      default:
        return 1;
    }
  };

  let remainingNumberOfCols = getNumberOfTotalColumnsToBeDisplayed(currentScreenSize);
  // let remainingNumberOfCols = maxColumns;
  const slicedColGroups = [];
  for (let i = 0; i < columngroups.length; i++) {
    if (remainingNumberOfCols === 0) {
      break;
    }

    const currColGroup = columngroups[i];
    const noOfColsInCurrColGroup = currColGroup.columns.length;
    if (remainingNumberOfCols - noOfColsInCurrColGroup < 0) {
      const slicedColumns = currColGroup.columns.slice(0, remainingNumberOfCols);
      const slicedColGroup = createColumnGroup(currColGroup.title, slicedColumns, currColGroup.rows);
      slicedColGroups.push(slicedColGroup);
    } else {
      slicedColGroups.push(currColGroup);
      remainingNumberOfCols -= noOfColsInCurrColGroup;
    }
  }

  let numberOfRows = 0;
  const columnGroupRows = slicedColGroups.map((p) => p.rows);
  if (columnGroupRows && columnGroupRows.length > 0) {
    numberOfRows = columnGroupRows[0].length;
  }

  return (
    <div className="table-expandable-wrapper">
      <div className="table-expandable">
        {slicedColGroups.map((colGroup, index) => (
          <React.Fragment key={`colGroup.title${index}`}
          >
            <TableColumnGroup
              sortedByColumn={sortedByColumn}
              isSortedAscending={isSortedAscending}
              currentSelectedPage={currentPage}
              numberOfItemsPerPage={numberOfItemsPerPage}
              columngroup={colGroup}
            />
          </React.Fragment>
        ))}
      </div>
      {hideFooter === true ? null : (
        <TableFooter
          currentScreenSize={currentScreenSize}
          numberOfItems={numberOfRows}
          currentSelectedPage={currentPage}
          numberOfItemsPerPage={numberOfItemsPerPage}
          onChangeNumberOfItemsPerPage={onChangeNumberOfItemsPerPage}
          onChangePage={onChangePage}
          hidePageInputs={hidePageInputs}
          translations={translations}
        />
      )}
    </div>
  );
}

export default ColumnGroupTable;

import { FunctionComponent, useState } from "react";
import * as React from 'react'
import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'
interface ExpandableRowProps {
    headerContent?: any;
    expandedContent?: any;
    addHeaderClasses?: string[]
    addExpandedContentClasses?: string[]
}

const ExpandableRow: FunctionComponent<ExpandableRowProps> = ({ headerContent, expandedContent, addHeaderClasses, addExpandedContentClasses }) => {

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const onExpandClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setIsExpanded(!isExpanded);
    };

    const [ref, bounds] = useMeasure();
    const props = useSpring({
        from: {
            // opacity: 0, 
            height: 0
        },
        to: {
            // opacity: isExpanded ? 1 : 0,
            height: isExpanded ? bounds.height : 0
        }
    });

    let classes = '';
    if (addHeaderClasses) {
        classes += addHeaderClasses.join(' ') + ' ';
    }
    classes += "dashboard-table-row dashboard-table-clickable-item";

    let expandedContentClasses = "dashboard-table-expanded-container";
    if (addExpandedContentClasses) {
        expandedContentClasses += ' ' + addExpandedContentClasses.join(' ');
    }

    return (<>
        <div className={classes} onClick={(e) => { onExpandClick(e) }}>
            {headerContent}
        </div>
        <animated.div style={{ ...props, overflow: "hidden" }}>
            <div className={expandedContentClasses} ref={ref}>
                {expandedContent}
            </div>
        </animated.div>

    </>)
}

export default ExpandableRow;
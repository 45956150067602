import React, { FunctionComponent } from 'react';

interface TextareaProps {
  title: any,
  val?: string | number | readonly string[],
  placeholder?: string,
  isRequired?: boolean,
  handleChange?: React.ChangeEventHandler<HTMLTextAreaElement>,
  errorMessage?: string,
  infoMessages?: string[],
  rows?: number,
  cols?: number,
  disabled?: boolean,
  isControlled?: boolean,
  register?: any,
  name?: string,
}

const Textarea: FunctionComponent<TextareaProps> = ({
  title, val, placeholder, isRequired, handleChange, errorMessage, infoMessages, rows, cols, disabled,
  isControlled, register, name,
}) => {
  const id = `input-${new Date().getTime()}`;
  return (
    <div className="form-input">
      <label htmlFor={id}>{title}</label>

      {isControlled === true
        ? (
          <textarea
            id={id}
            value={val}
            onChange={handleChange}
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            required={isRequired}
            {...register(name)}
            autoComplete="off"
            disabled={disabled}
          />
        )
        : (
          <textarea
            id={id}
            value={val}
            onChange={handleChange}
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            required={isRequired}
            autoComplete="off"
            disabled={disabled}
          />
        )}

      {errorMessage ? <div className="input-error-message">{errorMessage}</div> : null}
      {infoMessages ? infoMessages.map((p) => <div key={p} className="input-info-message">{p}</div>) : null}

    </div>
  );
}

export default Textarea;

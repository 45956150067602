import React, { FunctionComponent, useRef } from 'react';
import { IconContext } from 'react-icons/lib';
import {
  RiMoreFill,
} from 'react-icons/ri';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import { getUserTableSpanClass } from './TableUtils';

interface TableCellActionDropDownButtonProps {
  displayText?: string,
  component?: React.ReactNode,
  textComponent?: React.ReactNode,
  listItems: any[],
  onClickRow: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  clickable?: boolean,
  columnSize: string,
}

const TableCellActionDropDownButton: FunctionComponent<TableCellActionDropDownButtonProps> =
  ({ displayText,
    listItems,
    onClickRow,
    columnSize,
    component,
    textComponent,
    clickable
  }) => {
    const dropdownRef = useRef(null);
    const [dropDownVisible, toggleDropDown] = useDetectOutsideClick(dropdownRef, false);

    const onClickToggleDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      toggleDropDown(!dropDownVisible);
    };

    const ddlClasses = dropDownVisible ? 'dropdown-content icon-hover-child active' : 'icon-hover dropdown-content';
    const userTableSpanClass = `${getUserTableSpanClass(columnSize)} user-table-span-with-icon`;
    const wrapperClasses = clickable === false ? 'vertical-aligner vertical-space-flex' : 'clickable-cell vertical-aligner vertical-space-flex';

    return (
      <div onClick={onClickRow} className={wrapperClasses}>
        {component ?
          <div onClick={onClickToggleDropdown} ref={dropdownRef} className="dropdown">
            {component}
            <ul className={ddlClasses} >
              {listItems.map((listItem) => (listItem))}
            </ul>
          </div> :
          <>
            {textComponent ? textComponent : <span className={userTableSpanClass}>{displayText}</span>}
            <div onClick={onClickToggleDropdown} className="dropdown">
              <IconContext.Provider value={{ className: 'user-table-icon icon-hover clickable-cell' }}>
                <RiMoreFill />
              </IconContext.Provider>
              <ul className={ddlClasses} ref={dropdownRef}>
                {listItems.map((listItem) => (listItem))}
              </ul>
            </div>
          </>
        }
      </div>
    );
  }

export default TableCellActionDropDownButton;

export const FileTypes = Object.freeze({
  CustomerFile: 1,
  DashboardFile: 2,
  TrainingFile: 3,
});

export const DashboardTypes = Object.freeze({
  Open: 1,
  ExplicitPermissions: 2,
  // ReadOnly: 3,
});

export const Products = Object.freeze({
  MBS: 1,
  White: 2,
  Training: 3,
  Guidance: 4,
  Exercises: 5,
  MurphyStandard: 6,
  EventFlow: 7,
  ElearningSwe: 8,
  ElearningFtg: 9,
});

export const Roles = Object.freeze({
  User: 0,
  Admin: 1,
  ReadOnly: 2,
  Trial: 3,
});

export const TrainingTypes = Object.freeze({
  Course: 1,
  Excersise: 2,
  Guidance: 3,
});

export const CustomerUserTrainingSessionStates = Object.freeze({
  Booked: 1,
  Cancelled: 2,
  Completed: 3,
  NotAttended: 4,
});

export const TrainingFileTypes = Object.freeze({
  Misc: 1,
});

export const EventTypes = Object.freeze({
  Add: 1,
  Update: 2,
  Deleted: 3,
});

export const DashboardEntityTypes = Object.freeze({
  ActionsCard: 'ActionsCard',
  StatusCard: 'StatusCard',
  Goals: 'Goals',
  BackGround: 'BackGround',
  Assumption: 'Assumption',
  Party: 'Party',
  PartyPeople: 'PartyPeople',
  DashboardFile: 'DashboardFile',
  InformationCard: 'InformationCard',
  Dashboard: 'Dashboard',
  DashboardLog: 'DashboardLog',
  AlertUser: 'AlertUser',
});

export const LogTypes = Object.freeze({
  ExcelCreated: 0,
  PdfCreated: 1,
});

export const ActionCardStatuses = Object.freeze({
  NotStarted: 0,
  Ongoing: 1,
  Done: 2,
});

export const GoalTypes = Object.freeze({
  MainGoal: 0,
  ComGoal: 1,
});

export const MainLogReportTypes = Object.freeze({
  PDF: 1,
  Excel: 2,
});

export const StatusTypes = Object.freeze({
  Default: 1,
  MSB: 2,
});

export enum EventFlowComponentType {
  Title = 1,
  Text = 2,
  Accordion = 3,
  CheckList = 4,
  CompletedButton = 5,
};

export enum EventFlowCheckListItemType {
  TextInput = 1,
  Checkbox = 2,
  Select = 3,
};
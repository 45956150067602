import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import MurphySelect from "./MurphySelect";
import React from "react";

interface ControlledFormSelectProps {
    title?: string,
    direction?: string,
    control: any;
    name: string;
    label: string;
    placeholder?: string;
    isLoading?: boolean;
    options: OptionType[];
    isMulti: boolean;
    errorMessage?: string;
    disabled?: boolean;
}

export type OptionType = {
    value: string | number;
    label: string;
};

const ControlledFormSelect: FunctionComponent<ControlledFormSelectProps> = (
    { isMulti, disabled, direction, title, options, control, name, label, placeholder, isLoading, errorMessage, ...props }
) => {
    const id = `input-${new Date().getTime()}`;
    let classes = 'form-input';
    if (direction === 'column') {
        classes = ` ${'form-input-column-flow'}`;
    }

    return (
        <div className={classes}>
            {title ? <label htmlFor={id}>{title}</label> : null}
            <Controller
                {...props}
                control={control}
                name={name}
                render={({
                    field: { onChange, onBlur, value, name },
                }) =>
                (
                    isMulti === false ? <MurphySelect
                        placeholder={placeholder}
                        options={options}
                        isLoading={isLoading}
                        onChange={val => onChange(val.value)}
                        onBlur={onBlur}
                        isDisabled={disabled}
                        value={options.find(p => p.value == value)}
                        name={name}
                        isMulti={false}
                    /> :
                        <MurphySelect
                            placeholder={placeholder}
                            options={options}
                            isLoading={isLoading}
                            onChange={val => {
                                onChange(val.map(p => p.value as number))
                            }}
                            onBlur={onBlur}
                            isDisabled={disabled}
                            value={options.filter(p => value?.includes(p.value as number))}
                            name={name}
                            isMulti={true}
                        />
                )}
            />
            {errorMessage ? <div className="input-error-message">{errorMessage}</div> : null}
        </div>
    );
}

export default ControlledFormSelect;
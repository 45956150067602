import {
  ColumnGroup,
  ColumnGroupColumnCell,
  ColumnGroupRowCell,
} from "./Models";

const convertToComparableValue = (value: any) => {
  if (typeof value === "string") {
    return value.toUpperCase();
  }
  return value;
};

export const sortByKey = (columnKey: string, sortedAscending: boolean) => {
  const columnComponents = columnKey.split(".");

  const sortingFunction = (a, b) => {
    let currentA = { ...a };
    let currentB = { ...b };

    for (let i = 0; i < columnComponents.length - 1; i++) {
      const currentKey = columnComponents[i];
      const currentColKeyA = currentA[currentKey];
      const currentColKeyB = currentB[currentKey];

      if (currentColKeyA) {
        currentA = { ...currentColKeyA };
      }

      if (currentColKeyB) {
        currentB = { ...currentColKeyB };
      }
    }

    const lastKeyComponent = columnComponents[columnComponents.length - 1];
    const valA = convertToComparableValue(currentA[lastKeyComponent]);
    const valB = convertToComparableValue(currentB[lastKeyComponent]);

    if (valA === null && valB !== null) {
      return sortedAscending ? -1 : 1;
    }
    if (valA !== null && valB === null) {
      return sortedAscending ? 1 : -1;
    }
    if (valA === null && valB === null) {
      return 0;
    }

    if (sortedAscending) {
      if (valA < valB) {
        return -1;
      }
      if (valA > valB) {
        return 1;
      }
    } else {
      if (valA > valB) {
        return -1;
      }
      if (valA < valB) {
        return 1;
      }
    }
    return 0;
  };
  return sortingFunction;
};

export const createColumnGroup = (
  title: string,
  columns: ColumnGroupColumnCell[],
  rows: ColumnGroupRowCell[][]
) => {
  const columnGroup: ColumnGroup = {
    title: title,
    columns: columns,
    rows: rows,
  };

  return columnGroup;
};

export const getColSizeBasedOnScreen = (screenSize: string) => {
  switch (screenSize) {
    case "xs":
      return "column-small";
    case "sm":
      return "column-small-medium";
    case "md":
      return "column-small-medium";
    case "lg":
      return "column-medium";
    case "xl":
      return "column-medium-large";
    case "xl-name":
      return "column-large";
    default:
      return "user-table-span-medium";
  }
};

export const getColSizeFlexBasedOnScreen = (screenSize: string) => {
  switch (screenSize) {
    case "xs":
      return "column-medium-large";
    case "sm":
      return "column-large";
    case "md":
      return "column-extra-large";
    case "lg":
      return "column-extra-large";
    case "xl":
      return "column-extra-extra-large";
    case "xl-name":
      return "column-large";
    default:
      return "user-table-span-medium";
  }
};

export const getSmallColSizeBasedOnScreen = (screenSize: string) => {
  switch (screenSize) {
    case "xs":
      return "column-xsmall";
    case "sm":
      return "column-small";
    case "md":
      return "column-small-medium";
    case "lg":
      return "column-medium";
    case "xl":
      return "column-medium-large";
    case "xl-name":
      return "column-large";
    default:
      return "user-table-span-medium";
  }
};

export const getColSizeBasedOnScreenLargeNames = (screenSize: string) => {
  switch (screenSize) {
    case "xs":
      return "column-small-lname";
    case "sm":
      return "column-small-medium-lname";
    case "md":
      return "column-medium";
    case "lg":
      return "column-medium";
    case "xl":
      return "column-medium-large";
    case "xl-name":
      return "column-large";
    default:
      return "user-table-span-medium";
  }
};
export const getLargeColSizeBasedOnScreen = (screenSize: string) => {
  switch (screenSize) {
    case "xs":
      return "column-small-medium-lname";
    case "sm":
      return "column-medium";
    case "md":
      return "column-medium-large";
    case "lg":
      return "column-large";
    case "xl":
      return "column-extra-large";
    default:
      return "user-table-span-medium";
  }
};

export const getUserTableSpanClass = (size: string) => {
  switch (size) {
    case "column-large":
      return "user-table-span-large";
    case "column-large-auto":
      return "user-table-span-large-auto";
    case "column-medium-large":
      return "user-table-span-medium-large";
    case "column-medium":
      return "user-table-span-medium";
    case "column-small-medium":
      return "user-table-span-small-medium";
    case "column-small":
      return "user-table-span-small";
    default:
      return "user-table-span-medium";
  }
};

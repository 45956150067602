import React, { FunctionComponent } from "react";

interface OptionSetProps {
  direction?: string,
  optionSetId: any,
  optionSetName?: string,
  options: Option[],
  selectedOption: any,
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>, optionSetId: any) => void,
  disabled?: boolean,
}
export interface Option {
  id: number,
  value: string,
}

const OptionSet: FunctionComponent<OptionSetProps> = ({
  direction, optionSetId, optionSetName, options,
  selectedOption, handleChange, disabled,
}) => {
  let classes = 'form-input';
  if (direction === 'column') {
    classes = ` ${'form-input-column-flow'}`;
  }

  return (
    <div className={classes}>
      {optionSetName ? <label htmlFor={optionSetId}>{optionSetName}</label> : null}

      <select
        key={optionSetId}
        id={optionSetId}
        name={optionSetName}
        value={selectedOption}
        onChange={(event) => { handleChange(event, optionSetId); }}
        disabled={disabled}
      >
        {options.map((p) => <option key={p.id} value={p.id}>{p.value}</option>)}
      </select>
    </div>
  );
}

export default OptionSet;

import * as React from 'react';
import { FunctionComponent } from 'react';
import Tab from './Tab';

export interface TabType {
  id: number,
  displayText: string,
  customClass?: string
  customSelectedClass?: string,
}

interface TabPanelProps {
  tabs: TabType[];
  onClickTab: (id: number) => void;
  selectedTabId: number,
  customClass?: string,
}

const TabPanel: FunctionComponent<TabPanelProps> = ({ tabs, onClickTab, selectedTabId, customClass, }) => {
  let classes = 'tab-panel';
  if (customClass) {
    classes += ` ${customClass}`;
  }

  return (
    <div className={classes}>
      {tabs.map((tab) => (
        <Tab
          id={tab.id}
          key={tab.id}
          displayText={tab.displayText}
          isSelected={tab.id === selectedTabId}
          onClickTab={onClickTab}
          customClass={tab.customClass}
          customSelectedClass={tab.customSelectedClass}
        />
      ))}
    </div>
  );
}

export default TabPanel;

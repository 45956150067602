import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Log, User } from 'oidc-client-ts';
import { AuthProvider as OidcProvider, AuthProviderProps } from "react-oidc-context";

const OidcWrapper: React.FC<AuthProviderProps> = (props) => {
    const navigate = useNavigate();

    Log.setLogger(console);
    Log.setLevel(Log.DEBUG);

    const onSigninCallback = (_user: User | void): void => {
        let redirectUrl = _user?.state || window.location.pathname;
        navigate(redirectUrl, { replace: true });
    }

    const onRemoveUser = (): void => {
        window.location.href = '/login';
    }

    return (
        <OidcProvider {...props} onSigninCallback={onSigninCallback} onRemoveUser={onRemoveUser}>
            {props.children}
        </OidcProvider>
    );
};

export default OidcWrapper;

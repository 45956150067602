import React from 'react';
import ReactSelect, {
    GroupBase, Props,
    components,
    ControlProps,
    StylesConfig,
    CSSObjectWithLabel,
    InputProps,
} from 'react-select';

const customStyles: StylesConfig = {
    option: (provided: any, state: any) => ({
        ...provided,
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        color: 'black',

    }),
    control: (base: CSSObjectWithLabel, state: ControlProps<unknown, boolean, GroupBase<unknown>>) => {
        return {
            ...base,
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderRadius: 0,
            minHeight: '60px',
            fontSize: '14px',
            color: '#a5a3a9',
            outline: 'none',
            boxShadow: 'none',
            borderBottom: state.isFocused ? '3px solid #0065bd' : '2px solid #e0e1dd'
        }
    },
    singleValue: (provided: any, state: any) => ({
        ...provided,
        border: 0,
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',

    }),
    multiValue: (provided: any, state: any) => ({
        ...provided,
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        color: '#a5a3a9',
        borderRadius: '8px',
    }),
    input: (base: CSSObjectWithLabel, state: InputProps<unknown, boolean, GroupBase<unknown>>) => {
        return {
            ...base,
            borderRadius: 0,
            fontSize: '16px',
            maxWidth: '80vw',
            padding: '10px',
            width: '260px',
            outline: 'none',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
        }
    },
    placeholder: (provided: any, state: any) => ({
        ...provided,
        fontSize: '14px',
        color: '#a5a3a9'
    }),
}


// Good: Custom component declared outside of the Select scope
const Control = ({ children, ...props }) => {
    const style = { cursor: 'pointer', paddingLeft: '1rem' };

    //get placeholder from props
    const placeholder = props.selectProps.placeholder;

    //get value from props
    const value = props.selectProps.value;

    /* @ts-ignore */
    return <components.Control {...props}>
        <span style={style}>
            {(value && value?.label || value && value?.length > 0) ? placeholder : ""}
        </span>
        {children}
    </components.Control>
}


function MurphySelect<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>, label?: string,) {
    return (
        <ReactSelect

            styles={customStyles}
            {...props}
            components={{ Control }}
            filterOption={(option, rawInput) => {
                // search by starts with ignoring case
                return option.label.toLowerCase().startsWith(rawInput.toLowerCase());
            }}
        />
    );
}

export default MurphySelect;
import { SigninRedirectArgs, SigninSilentArgs, SignoutRedirectArgs, User } from "oidc-client-ts";

export interface IAuthProfile {
	sub: string;
	name: string;
	azp: string;
	aud: string | string[];
	iss: string;
}
export interface IAuthUser {
	idToken?: string;
	accessToken: string;
	refreshToken?: string | null;
	tokenType: string;
	expiresAt: number;
	profile: IAuthProfile;
}

export interface IAuthService {
	getUserStorageString: () => string;
	login: (redirectUrlAfterLogin?: string, provider?: string, userName?: string, signIn?: (args?: SigninRedirectArgs) => Promise<void>) => Promise<IAuthUser | void>;
	renewToken: (refreshToken?: string | null, renew?: (args?: SigninSilentArgs) => Promise<User>) => Promise<IAuthUser | null>;
	signOut: (successCallback: any, finallyCallback?: any, provider?: string,
		signOut?: (args?: SignoutRedirectArgs) => Promise<void>,
		removeUser?: () => Promise<void>) => void;
	signalLogout: () => void;
	logout: (loginPageUrl?: string, idToken?: string) => Promise<void>;
	logoutHard: () => void;
	forgotPassword: () => Promise<void>;
	changePassword: () => Promise<void>;
	createAccount: () => Promise<void>;
	getUser: (user?: User) => Promise<IAuthUser | null>;
	getLoginUrl: () => string;
}

let AuthServiceType = {
	IAuthService: Symbol('IAuthService'),
};

export default AuthServiceType;

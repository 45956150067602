import parse from 'html-react-parser';
import linkifyHtml from 'linkifyjs/lib/linkify-html';
import DOMPurify from 'dompurify';

/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
export const uniqueItems = (a, property) => {
  const seen = {};
  const out = [];
  const len = a.length;
  let j = 0;
  for (let i = 0; i < len; i++) {
    const item = a[i];
    const key = item[property];
    if (!key) {
      out[j++] = item;
    } else if (seen[key] !== 1) {
      seen[key] = 1;
      out[j++] = item;
    }
  }
  return out;
};

export const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

/** assumes array elements are primitive types
* check whether 2 arrays are equal sets.
* @param  {} a1 is an array
* @param  {} a2 is an array
*/
export const areArraysEqualSets = (a1, a2) => {
  const superSet = {};
  for (const i of a1) {
    const e = i + typeof i;
    superSet[e] = 1;
  }

  for (const i of a2) {
    const e = i + typeof i;
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (const e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
};

export const truncate = (str, n) => ((str.length > n) ? `${str.substr(0, n - 1)}...` : str);

export const downloadFromLink = (fileName, hrefContents) => {
  const tempLink = document.createElement('a');
  tempLink.href = URL.createObjectURL(hrefContents);
  tempLink.download = fileName;
  tempLink.type = 'text/html';
  document.body.appendChild(tempLink);
  tempLink.click();
  tempLink.remove();
};

export const convertNewLinesToBreak = (str) => str.replace(/(?:\r\n|\r|\n)/g, '<br/>');
export const convertBreaksToNewLine = (str) => str.replace(/<br\s*[\/]?>/gi, '\n');

export const parseHtmlAndLinkifySafe = (str: string) => {
  if (str) {
    return parse(DOMPurify.sanitize(linkifyHtml(str)));
  }
  return '';
};

export const ordinalCompare = (a: string, b: string | undefined) => {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a === b;
}

export const validateEmail = (email: string | null | undefined): boolean => {
  const validEMail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return validEMail.test(email) ? false : true;
}
export const validatePhone = (phone: string | null | undefined): boolean => {
  const validPhone = /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){8,13}\d$/
  return validPhone.test(phone) ? false : true;
}

export const extractDomainFromEmail = (email) => {
  // Regular expression pattern to match the domain in an email address
  var domainRegex = /@([\w-]+\.[\w-]+)/;

  // Extract the domain using the regex pattern
  var matches = email.match(domainRegex);

  // Return the extracted domain or null if no match was found
  return matches ? matches[1] : null;
}

import React, { FunctionComponent } from 'react';
import { ColumnGroupColumnCell } from './Models';
import TableColumnCell from './TableColumnCell';

interface TableHeaderColumnProps {
  columns: ColumnGroupColumnCell[],
  sortedByColumn: any,
  isSortedAscending: boolean,
}

const TableHeaderColumn: FunctionComponent<TableHeaderColumnProps> = ({ columns, sortedByColumn, isSortedAscending }) => {
  return (
    <div>
      {columns.map((col) => (
        <React.Fragment           key={col.columnKey}
        >
  <TableColumnCell
          key={col.columnKey}
          column={col}
          isSorted={col.columnKey == sortedByColumn}
          isSortedAscending={isSortedAscending}
        />
        </React.Fragment>
      ))}
    </div>
  );
}

export default TableHeaderColumn;
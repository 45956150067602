import { injectable } from "inversify";

@injectable()
export class TokenRefreshManager {
    refreshingAccessToken: boolean;

    constructor() {
        this.refreshingAccessToken = false;
    }

    isRefreshingAccessToken(): boolean {
        return this.refreshingAccessToken;
    }

    setRefreshingAccessToken(refreshing: boolean): void {
        this.refreshingAccessToken = refreshing;
    }
}
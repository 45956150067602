/* eslint-disable import/prefer-default-export */
import axios, { AxiosRequestConfig, AxiosResponse, Method, ResponseType } from 'axios';
import { inject, injectable } from 'inversify';
import { FileTypes } from '../enums';
import { IApiService } from '../interfaces/IApiService';
import AuthServiceType, { IAuthService, IAuthUser } from '../interfaces/IAuthService';
import { TokenRefreshManager } from './TokenRefreshManager';

@injectable()
export default class ApiService implements IApiService {
  baseUrl: string;

  constructor(@inject(AuthServiceType.IAuthService) private authService: IAuthService, baseUrl: string
    , private tokenRefreshManager?: TokenRefreshManager) {
    this.baseUrl = baseUrl;
  }

  callApiWithToken = (token: string, url: string, method: Method, sendData: any, overrideHeaders: any, overrideResponseType: ResponseType) => {
    let headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    };

    if (overrideHeaders) {
      headers = overrideHeaders;
      headers.Authorization = `Bearer ${token}`;
    }

    const config: AxiosRequestConfig = {
      baseURL: this.baseUrl,
      method,
      url,
      headers,
    };

    if (overrideResponseType) {
      config.responseType = overrideResponseType;
    }

    if (sendData) {
      config.data = sendData;
    }

    return axios(config);
  };

  callApiWithoutToken = (url: string, method: Method, sendData?: any, overrideHeaders?: any, overrideResponseType?: ResponseType) => {
    let headers = {
      Accept: 'application/json',
    };

    if (overrideHeaders) {
      headers = overrideHeaders;
    }

    const config: AxiosRequestConfig = {
      baseURL: this.baseUrl,
      method,
      url,
      headers,
    };

    if (overrideResponseType) {
      config.responseType = overrideResponseType;
    }

    if (sendData) {
      config.data = sendData;
    }

    return axios(config);
  };

  callApi = async (url: string, method: Method, sendData?: any, overrideHeaders?: any, overrideResponseType?: any): Promise<AxiosResponse<any>> => {
    try {
      const getUserFunc = this.authService.getUser;
      const user = await getUserFunc();
      const result = await this.callOrRenew(user, url, method, sendData, overrideHeaders, overrideResponseType);
      return result;
    } catch (error) {
      throw error;
    }
  }

  callOrRenew = async (user: IAuthUser, url: string, method: Method, sendData?: any, overrideHeaders?: any, overrideResponseType?: any) => {
    const d = new Date();
    const time = d.getTime();
    // if (user && user.accessToken && time < user.expiresAt) {
    if (user && user.accessToken) {
      try {
        return await this.callApiWithToken(user.accessToken, url, method, sendData, overrideHeaders, overrideResponseType);
      } catch (error) {
        if (error.response.status === 401) {
          // If tokenRefreshManager is defined, check if refresh is already in progress
          if (!this.tokenRefreshManager || (this.tokenRefreshManager && !this.tokenRefreshManager.isRefreshingAccessToken())) {
            // If tokenRefreshManager is defined, set the refresh flag to true
            if (this.tokenRefreshManager) {
              this.tokenRefreshManager.setRefreshingAccessToken(true);
            }

            // Proceed with token renewal
            return this.authService.renewToken(user.refreshToken)
              .then((renewedUser) => {
                // If tokenRefreshManager is defined, reset the refresh flag once refresh is complete
                if (this.tokenRefreshManager) {
                  this.tokenRefreshManager.setRefreshingAccessToken(false);
                }
                return this.callApiWithToken(renewedUser.accessToken, url, method, sendData, overrideHeaders, overrideResponseType);
              })
              .catch((refreshError) => {
                // If tokenRefreshManager is defined, reset the refresh flag if refresh encounters an error
                if (this.tokenRefreshManager) {
                  this.tokenRefreshManager.setRefreshingAccessToken(false);
                }
                throw refreshError;
              });
          }
        }

        throw error;
      }
    }
    throw new Error('User is not logged in');
  }


  getLangTranslations = (lang: string) => {
    const url = `/GetAllText/${lang}`;
    return this.callApiWithoutToken(url, 'GET');
  };

  downloadExcel = (dashboardId: string, lang: string, utcOffsetInMinutes: number) => {
    const url = `/CreateExcelFileFromDashboard?dashboardId=${dashboardId}&lang=${lang}&minutesfromutc=${utcOffsetInMinutes}`;
    const responseType = 'blob';
    return this.callApi(url, 'GET', null, null, responseType);
  };

  addFileToCustomer = (fileToUpload: Blob, customerId: string) => {
    const url = 'api/File';

    const formData = new FormData();
    formData.append('File', fileToUpload);

    const createFileRequest = {
      FileTypeId: FileTypes.CustomerFile,
      CustomerId: customerId,
    };
    formData.append('FileJsonData', JSON.stringify(createFileRequest));

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return this.callApi(url, 'POST', formData, headers);
  };

  getFile = (id: string) => {
    const url = `/api/File/${id}`;
    const responseType = 'blob';
    return this.callApi(url, 'GET', null, null, responseType);
  };
}
import React, { useState, useEffect, FunctionComponent } from 'react';
import Button from '../Button';
import Input from '../Input';

interface TableFooterProps {
  numberOfItemsPerPage: number,
  currentSelectedPage: number,
  numberOfItems: number,
  currentScreenSize: string,
  onChangePage: (page: number) => void,
  onChangeNumberOfItemsPerPage: (numberOfItems: number) => void,
  hidePageInputs: boolean,
  translations?: any,
}

const TableFooter: FunctionComponent<TableFooterProps> = ({
  numberOfItemsPerPage,
  currentSelectedPage,
  numberOfItems,
  currentScreenSize,
  onChangePage,
  onChangeNumberOfItemsPerPage,
  hidePageInputs,
  translations
}) => {
  const [pageNumberCells, setPageNumberCells] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [chunkSize, setChunkSize] = useState(0);
  const [currentChunk, setCurrentChunk] = useState(0);

  const getMaxNumberOfPagesVisible = (screenSize: string) => {
    switch (screenSize) {
      case 'xs':
        return 3;
      case 'sm':
        return 4;
      case 'md':
        return 6;
      case 'lg':
        return 10;
      default:
        return 5;
    }
  };

  const handleShowNextPages = () => {
    const nextChunk = currentChunk + 1;
    const nextChunkSafe = Math.min(Math.ceil(numberOfItems / numberOfItemsPerPage), nextChunk);
    setCurrentChunk(nextChunkSafe);
  };

  const handleShowPreviousPages = () => {
    const nextChunk = currentChunk - 1;
    const nextChunkSafe = Math.max(0, nextChunk);
    setCurrentChunk(nextChunkSafe);
  };

  const generatePageNumbers = (nrOfPages: number, selectedPage: number, currentChunkSize: number, currentChnk: number) => {
    const pageList = [];
    const currentChnkSafe = currentChnk || 0;
    const nrOfChunks = Math.ceil(nrOfPages / currentChunkSize);
    const startItemIndex = currentChnkSafe * currentChunkSize;
    const endItemIndex = Math.min(startItemIndex + currentChunkSize, nrOfPages);

    if (currentChnkSafe > 0) {
      const previousItemsCell = (
        <div key={-2} className="table-footer-box vertical-aligner horizontal-aligner" onClick={() => handleShowPreviousPages()}>
          ...
        </div>
      );
      pageList.push(previousItemsCell);
    }

    for (let i = startItemIndex; i < endItemIndex; i++) {
      let classes = 'table-footer-box vertical-aligner horizontal-aligner';
      if (selectedPage - 1 === i) {
        classes += ' table-footer-selected';
      }
      const tableRowCell = (
        <div key={i + 1} className={classes} onClick={() => onChangePage(i + 1)}>
          {i + 1}
        </div>
      );
      pageList.push(tableRowCell);
    }

    if (nrOfPages > currentChunkSize) {
      if (currentChnkSafe < nrOfChunks - 1) {
        const moreItemsCell = (
          <div key={-1} className="table-footer-box vertical-aligner horizontal-aligner" onClick={() => handleShowNextPages()}>
            ...
          </div>
        );
        pageList.push(moreItemsCell);

        let lastPageClass = 'table-footer-box vertical-aligner horizontal-aligner';
        if (selectedPage === nrOfPages) {
          lastPageClass += ' table-footer-selected';
        }
        const lastPageCell = (
          <div key={nrOfPages} className={lastPageClass} onClick={() => onChangePage(nrOfPages)}>
            {nrOfPages}
          </div>
        );
        pageList.push(lastPageCell);
      }
    }

    return pageList;
  };

  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(event.target.value, 10);
    onChangePage(val);
  };

  const incrementPage = (noOfPages: number, currSelectedPage: number) => {
    let val = currSelectedPage + 1;
    if (val > noOfPages) {
      val = noOfPages;
    }
    onChangePage(val);
  };

  const decrementPage = (currSelectedPage: number) => {
    let val = currSelectedPage - 1;
    if (val < 1) {
      val = 1;
    }
    onChangePage(val);
  };

  const handleChangeNumberOfItemsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    onChangeNumberOfItemsPerPage(newValue);
  }

  useEffect(() => {
    const currentPageNumberCells = generatePageNumbers(numberOfPages, currentSelectedPage, chunkSize, currentChunk);
    setPageNumberCells(currentPageNumberCells);
  }, [numberOfPages, chunkSize, currentSelectedPage, currentChunk]);

  useEffect(() => {
    let chnkSizeSafe = 1;
    if (chunkSize > 0) {
      chnkSizeSafe = chunkSize;
    }
    const currentPossibleChunk = Math.ceil(currentSelectedPage / chnkSizeSafe) - 1;
    setCurrentChunk(currentPossibleChunk);
  }, [currentSelectedPage, chunkSize]);

  useEffect(() => {
    let numberOfItemsPerPageSafe = 1;
    if (numberOfItemsPerPage > 1) {
      numberOfItemsPerPageSafe = numberOfItemsPerPage;
    }

    const maxChunkSizeForScreen = getMaxNumberOfPagesVisible(currentScreenSize);
    const nrOfPages = Math.ceil(numberOfItems / numberOfItemsPerPageSafe);
    let chnkSize = nrOfPages;

    if (nrOfPages > maxChunkSizeForScreen) {
      chnkSize = maxChunkSizeForScreen;
    }
    setChunkSize(chnkSize);
    setNumberOfPages(nrOfPages);
  }, [currentScreenSize, numberOfItemsPerPage, numberOfItems]);
  return (
    <div className="vertical-aligner horizontal-aligner">
      <div className="table-footer">
        {hidePageInputs === true
          ? null
          : (
            <>
              <div className="vertical-aligner horizontal-aligner">
                <span className="table-footer-span">
                  {translations?.show ?? 'Show'}
                </span>
                <Input type="number" handleChange={handleChangeNumberOfItemsPerPage} val={numberOfItemsPerPage} min={1} max={numberOfItems} />
                <span className="table-footer-span">
                  {translations?.perpage ?? 'per page'}
                </span>
              </div>
              <div className="vertical-aligner horizontal-aligner">
                <span className="table-footer-span">
                  {translations?.gotopage ?? 'Go to page'}
                </span>
                <Input type="number" handleChange={handlePageChange} val={currentSelectedPage} min={1} max={numberOfPages} />
              </div>
            </>
          )}
        <div className="table-footer-buttons-container">
          <div className="table-footer-box vertical-aligner horizontal-aligner">
            <Button isIconButton buttonStyle="btn--prev" buttonSize="btn-with-icon" onClick={() => decrementPage(currentSelectedPage)} />
          </div>
          <div className="table-footer-numbers">
            {pageNumberCells.map((p) => p)}
          </div>
          <div className="table-footer-box vertical-aligner horizontal-aligner">
            <Button isIconButton buttonStyle="btn--next" buttonSize="btn-with-icon" onClick={() => incrementPage(numberOfPages, currentSelectedPage)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableFooter;

import { useRef, useEffect, FunctionComponent } from 'react';
import React from 'react';
import Button from '../Button';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
         * Alert if clicked on outside of element
         */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export interface ModalConfiguration {
  okCallback?: () => void,
  onCloseModal?: () => void,
  type?: 'error' | 'warning' | 'info' | 'trainingCompleted' | 'trainingBooked' | 'filter',
  centerContent?: boolean,
  title?: string,
  body?: any,
  hideButton?: boolean,
  buttonText?: string,
  disableOverflow?: boolean,
  position?: 'center' | 'top',
}


interface ModalProps {
  toggleModal: () => void,
  configuration: ModalConfiguration,
}

const Modal: FunctionComponent<ModalProps> = ({ toggleModal, configuration, children }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => { });

  const handleClick = () => {
    if (configuration.okCallback) {
      (configuration.okCallback)();
    }
    toggleModal();
  };

  let headerClass = 'modal-header';
  let bodyClass = 'modal-body';

  if (configuration.disableOverflow === true) {
    bodyClass += ' disable-overflow';
  }

  if (configuration.type === 'trainingCompleted') {
    headerClass += ' modal-header-education-course-completed'
  }
  if (configuration.type === 'trainingBooked') {
    headerClass += ' modal-header-education-course-booked'
  }
  if (configuration.type === 'filter') {
    bodyClass += ' pbs-wrapper';

  }
  if (configuration.type === 'error') {
    headerClass += ' modal-header-error';
  }
  if (configuration.type === 'warning') {
    headerClass += ' modal-header-warning';
  }
  if (configuration.centerContent === true) {
    bodyClass += ' pbs-wrapper';
  }

  let contentClass = 'modal-content';

  let modalClass = 'modal';
  if (configuration.position === 'center') {
    modalClass += ' center';
  }

  const onClickClose = () => {
    if (configuration.onCloseModal) {
      configuration.onCloseModal();
    }
    toggleModal();
  }

  return (
    <div className={modalClass}>

      <div ref={wrapperRef} className={contentClass}>
        <div className={headerClass}>
          <span onClick={onClickClose} onKeyUp={onClickClose} role="button" tabIndex={0} className="close">&times;</span>
          <h2>{configuration.title}</h2>
        </div>
        <div className={bodyClass}>
          {configuration.body || children}
        </div>

        {configuration.hideButton === true ? null
          : (
            <div className="modal-footer">
              <Button buttonStyle="btn--primary" buttonSize="btn--medium" onClick={handleClick}>{configuration.buttonText}</Button>
              {' '}
            </div>
          )}
      </div>

    </div>
  );
}

export default Modal;

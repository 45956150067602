import { FunctionComponent } from "react";
import SimpleResponsiveTableHeader from "./SimpleResponsiveTableHeader";
import SimpleResponsiveListRow from "./SimpleResponsiveListRow";
import React from "react";
import { SimpleResponsiveListColumnModel, SimpleResponsiveListRowModel } from "./models";

interface SimpleResponsiveTableProps {
    columns: SimpleResponsiveListColumnModel[],
    rows: SimpleResponsiveListRowModel[],
    onClickRow?: (id: string) => void,
}

const SimpleResponsiveTable: FunctionComponent<SimpleResponsiveTableProps> = (
    {
        columns,
        rows,
        onClickRow,
    }
) => {
    const gridStyle = columns.map((col) => col.columnSize).join('fr ');
    const gridTemplateCols = { gridTemplateColumns: `${gridStyle}fr` };

    return (
        <div className="table-wrapper">
            <div className="table-container">
                <ol className="collection collection-container">
                    <SimpleResponsiveTableHeader columns={columns} gridStyle={gridTemplateCols} />
                    {
                        rows.map((row) => (
                            <SimpleResponsiveListRow
                                key={row.id}
                                clickable={row.clickable}
                                handleClick={onClickRow ? onClickRow : () => { }}
                                row={row}
                                columns={columns}
                                gridStyle={gridTemplateCols}
                            />
                        ))
                    }
                </ol>
            </div>
        </div>
    );
}

export default SimpleResponsiveTable;
import * as React from 'react';
import { FunctionComponent, useRef } from 'react';
import useDetectOutsideClick from '../../../web/src/common/hooks/useDetectOutsideClick';


interface AddFilterDropdownProps {
  filters: JSX.Element[],
  translations?: Record<string, string>,
  buttonText?: string
}

const AddFilterDropdown: FunctionComponent<AddFilterDropdownProps> = ({ filters, translations, buttonText }) => {
  const dropdownRef = useRef(null);
  const [dropDownVisible, toggleDropDown] = useDetectOutsideClick(dropdownRef, false);

  const onClickToggleDropdown = (event) => {
    event.stopPropagation();
    toggleDropDown(!dropDownVisible);
  };

  const ddlClasses = dropDownVisible ? 'filter-dropdown-content active' : 'filter-dropdown-content';

  return (
    <div onClick={onClickToggleDropdown} className="filter-control-box add-filter-box">
      <div className="filter-control-box-text">
        {buttonText || (translations ? translations.addfilter : "Add filter")}
      </div>
      <div className="filter-dropdown filter-control-box-remove-button">
        +
        <ul className={ddlClasses} ref={dropdownRef}>
          {filters}
        </ul>
      </div>

    </div>
  );
}

export default AddFilterDropdown;
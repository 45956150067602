import React, { FunctionComponent } from 'react';
import TableHeaderColumn from './TableHeaderColumn';
import TableColumnGroupRow from './TableColumnGroupRow';
import { ColumnGroup, ColumnGroupRowCell } from './Models';

interface TableColumnGroupProps {
  columngroup: ColumnGroup,
  sortedByColumn: any,
  isSortedAscending: boolean,
  currentSelectedPage: number,
  numberOfItemsPerPage: number,
}

const TableColumnGroup: FunctionComponent<TableColumnGroupProps> = ({
  columngroup,
  sortedByColumn,
  isSortedAscending,
  currentSelectedPage,
  numberOfItemsPerPage,
}) => {
  const rowsSortedAccordingToColumns: ColumnGroupRowCell[][] = [];
  const startIndex = (currentSelectedPage - 1) * numberOfItemsPerPage;
  const endIndex = startIndex + numberOfItemsPerPage;
  const rowsToBeDisplayed = columngroup.rows.slice(startIndex, endIndex);

  rowsToBeDisplayed.forEach((row) => {
    const sortedRow: ColumnGroupRowCell[] = [];
    columngroup.columns.forEach((col) => {
      const matchingValue = row.filter((item: ColumnGroupRowCell) => item.columnKey === col.columnKey);
      if (matchingValue) {
        sortedRow.push({ ...matchingValue[0], size: col.size });
      } else {
        sortedRow.push({
          columnKey: col.columnKey,
          id: row[0].id,
          rowId: row[0].rowId,
          title: row[0].title,
          displayValue: false,
          size: col.size,
        });
      }
    });
    rowsSortedAccordingToColumns.push(sortedRow);
  });

  return (
    <div className={"table-column-group-wrapper"}>
      <div className="table-column-group-header-label">{columngroup.title}</div>
      <div className="table-column-group">
        <TableHeaderColumn
          columns={columngroup.columns}
          sortedByColumn={sortedByColumn}
          isSortedAscending={isSortedAscending}
        />
        {rowsSortedAccordingToColumns.map((row, index) => <React.Fragment key={index}><TableColumnGroupRow cells={row} /></React.Fragment>)}
      </div>
    </div>
  );
}

export default TableColumnGroup;

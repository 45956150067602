import React, { FunctionComponent } from 'react';
import { IoSearchSharp } from 'react-icons/io5';
import { IconContext } from 'react-icons/lib';

interface SearchBarProps {
  val?: string,
  placeholder?: string,
  type?: React.HTMLInputTypeAttribute,
  name?: string,
  handleChange?: React.ChangeEventHandler<HTMLInputElement>,
  disabled?: boolean,
  showSearchIcon?: boolean,
  onClickSearchIcon?: () => void,
  searchResultsContainer?: any,
  customClasses?: string[],
}

const SearchBar: FunctionComponent<SearchBarProps> = ({
  val, placeholder, type, name,
  handleChange,
  disabled,
  showSearchIcon, onClickSearchIcon,
  searchResultsContainer,
  customClasses,
}) => {
  const id = `input-${new Date().getTime()}`;
  let classes = 'search-bar-murphy vertical-aligner';
  if (customClasses) {
    classes += ` ${customClasses.join(' ')}`;
  }

  const onClickSearch = onClickSearchIcon ? onClickSearchIcon : () => { };

  return (
    <>
      <div className={classes}>
        <input
          id={id}
          value={val}
          onChange={handleChange}
          autoComplete="off"
          placeholder={placeholder}
          type={type}
          name={name}
          disabled={disabled}
        />

        {showSearchIcon ? (
          <div onClick={onClickSearch} className="btn-icon-container btn-icon-positive vertical-aligner horizontal-aligner">
            <IconContext.Provider value={{ className: 'btn-icon' }}>
              <IoSearchSharp />
            </IconContext.Provider>
          </div>
        ) : null}
      </div>

      {searchResultsContainer && (
        <div className="search-bar-murphy-searchresults-container">
          {searchResultsContainer}
        </div>
      )}

    </>
  );
}

export default SearchBar;

import React, { FunctionComponent } from 'react';
import { SimpleResponsiveListColumnModel } from './models';

interface SimpleResponsiveTableHeaderProps {
    columns: SimpleResponsiveListColumnModel[],
    gridStyle: any,
}

const SimpleResponsiveTableHeader: FunctionComponent<SimpleResponsiveTableHeaderProps> = (
    {
        columns,
        gridStyle,
    }
) => {
    return (
        <li className="item item-container" style={gridStyle}>
            {columns.map((col) => (<div className="attribute" key={col.title} data-name={col.title} title={col.title}>{col.title}</div>))}
        </li>
    );
}

export default SimpleResponsiveTableHeader;
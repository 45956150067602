export interface IPersistenceService {
    getProvider: () => string | null;
    saveProvider: (provider: string) => void;
    saveCurrentObjectId: (id: string) => void;
    getCurrentObjectId: () => string | null;
    saveLogoutTriggeredFromPortal: (value: boolean) => void;
    getLogoutTriggeredFromPortal: () => boolean | null;
    clearNonPersistentItems: () => void;
    setSelectedCustomerId: (id: string) => void;
    getSelectedCustomerId: () => string | null;
    getTranslations: () => string | null;
    setTranslations: (value: string) => void;
    setSelectedTimeZone: (value: string) => void;
    getSelectedTimeZone: () => string | null;
    getCourseFilter: () => string | null;
    setCourseFilter: (value: string) => void;
    setLanguage: (value: string) => void;
    getLanguage: () => string | null;
    setSelectedCourseLanguages: (value: string) => void;
    getSelectedCourseLanguages: () => string | null;
    setLoginFirstRedirectHasBeenMade?: (value: boolean) => void;
    getLoginFirstRedirectHasBeenMade?: () => boolean;
    setOnboaringFlowCurrentStep: (onboardingFlowId: number, step: number) => void;
    getOnboaringFlowCurrentStep: (onboardingFlowId: number) => number | null;
}

export enum LocalStorageKeys {
    SelectedCustomerId = 'selectedCustomerId',
    CurrentObjectId = 'currentObjectId',
    Language = 'lang',
    Translations = 'translations',
    SelectedTimeZone = 'selectedTimeZone',
    LogoutTriggeredFromPortal = 'logoutTriggeredFromPortal',
    SelectedCourseLanguages = 'selectedCourseLanguages',
    CourseFilter = 'courseFilter',
    LoginFirstRedirectHasBeenMade = 'loginFirstRedirectHasBeenMade',
}

let PersistenceType = {
    IPersistenceService: Symbol('IPersistenceService'),
};

export default PersistenceType;

import * as React from 'react';
import { FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { IconContext } from 'react-icons/lib';
import { MdClose } from 'react-icons/md';


interface MurphyDatePickerProps {
  isControlled: boolean,
  control?: any,
  direction?: string,
  errorMessage?: string,
  title?: string,
  onChange?: (date: any) => void,
  locale: string,
  name?: string,
  disabled?: boolean,
  placeholderText?: string,
  selected?: Date;
  showTime?: boolean,
  showClearIcon?: boolean,
  onClickClear?: () => void,
}

const MurphyDatePicker: FunctionComponent<MurphyDatePickerProps> =
  ({ onClickClear,
    showClearIcon,
    showTime,
    selected,
    isControlled,
    control,
    direction,
    errorMessage,
    title,
    onChange,
    locale,
    name,
    disabled,
    placeholderText }) => {
    const id = `datepicker-${new Date().getTime()}`;
    let classes = 'form-input';
    if (direction === 'column') {
      classes = ` ${'form-input-column-flow'}`;
    }

    const dateFormat = showTime === true ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd";

    return (
      <div className={classes}>
        {title ? <label htmlFor={id}>{title}</label> : null}
        {isControlled === true
          ? (
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <DatePicker
                  showTimeSelect={showTime}
                  placeholderText={placeholderText || 'Select date'}
                  dateFormat={dateFormat}
                  locale={locale}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  disabled={disabled}
                />
              )}
            />
          )
          : (
            <DatePicker
              showTimeSelect={showTime}
              placeholderText={placeholderText || 'Select date'}
              dateFormat={dateFormat}
              locale={locale}
              onChange={onChange}
              readOnly={disabled}
              selected={selected}
            />
          )}

        {showClearIcon &&
          <div className="dashboard-search-inner-icon vertical-aligner horizontal-aligner" onClick={() => onClickClear()}>
            <IconContext.Provider value={{ className: 'dashboard-search-bar-icon' }}>
              <MdClose />
            </IconContext.Provider>
          </div>}

        {errorMessage ? <div className="input-error-message">{errorMessage}</div> : null}
      </div>
    );
  }

export default MurphyDatePicker;
/* eslint-disable no-undef */
import { Products } from './enums';

export const getCourseColorClassName = (name) => {
  switch (name) {
    case 'White':
      return 'white-module-color';
    case 'Courses':
      return 'courses-module-color';
    case 'Courses-Completed':
      return 'courses-completed-module-color';
    case 'Courses-Booked':
      return 'courses-booked-module-color';
    case 'MBS':
      return 'mbs-module-color';
    case 'Excercises':
      return 'excercises-module-color';
    case 'Training':
      return 'courses-module-color';
    default:
      return 'default-module-color';
  }
};

export const getProductColorClassById = (productId) => {
  switch (productId) {
    case Products.White:
      return 'white-module-color';
    case Products.Training:
      return 'courses-module-color';
    case Products.MBS:
      return 'mbs-module-color';
    case Products.MurphyStandard:
      return 'pyramid-module-color';
    case Products.Exercises:
      return 'excercises-module-color';
    case Products.EventFlow:
      return 'eventflow-module-color';
    default:
      return 'default-module-color';
  }
};

export const getTrainingSessionStateNames = (id, lang) => {
  switch (id) {
    case 0:
      switch (lang) {
        case 'swe':
          return 'BOKNINGSBAR';
        case 'eng':
          return 'BOOKABLE';
        case 'nor':
          return 'KAN BOOKES';
        default:
          return 'BOKNINGSBAR';
      }
    case 1:
      switch (lang) {
        case 'swe':
          return 'REDAN BOKAD';
        case 'eng':
          return 'BOOKED';
        case 'nor':
          return 'ALLREDE BOOKET';
        default:
          return 'BOOKED';
      }
    case 2:
      switch (lang) {
        case 'swe':
          return 'BOKNINGSBAR';
        case 'eng':
          return 'BOOKABLE';
        case 'nor':
          return 'KAN BOOKES';
        default:
          return 'BOKNINGSBAR'; // actually means cancelled
      }

    case 3:
      switch (lang) {
        case 'swe':
          return 'AVSLUTAD';
        case 'eng':
          return 'FINISHED';
        case 'nor':
          return 'GJENNOMFØRT';
        default:
          return 'AVSLUTAD';
      }

    case 4:
      switch (lang) {
        case 'swe':
          return 'NÄRVARADE EJ';
        case 'eng':
          return 'DID NOT ATTEND';
        case 'nor':
          return 'IKKE MØTTE';
        default:
          return 'NÄRVARADE EJ';
      }

    default:
      switch (lang) {
        case 'swe':
          return 'BOKNINGSBAR';
        case 'eng':
          return 'BOOKABLE';
        case 'nor':
          return 'KAN BOOKES';
        default:
          return 'BOKNINGSBAR';
      }
  }
};

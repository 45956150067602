import { inject, injectable } from 'inversify';
import {
	UserManager,
	Log,
	WebStorageStateStore,
	UserManagerSettings,
	User,
	SigninRedirectArgs,
	SignoutRedirectArgs,
	SigninSilentArgs,
} from 'oidc-client-ts';
import { IAuthService, IAuthUser } from '@murphy-frontend/common/interfaces/IAuthService';
import PersistenceType, {
	IPersistenceService,
} from '@murphy-frontend/common/interfaces/IPersistenceService';

export interface AuthServiceProps {
	baseAuthServerUrl: string;
	redirectUrl: string;
	postLogoutUrl: string;
	clientId: string;
	forgotPassClientId?: string;
	forgotPassTenantName?: string;
	forgotPassTenantId?: string;
	forgotPassPolicyName?: string;
	changePassPolicyName?: string;
	stateStore: WebStorageStateStore; //<
	automaticSilentRenew: boolean;
	enableForgotPassword: boolean;
	enableChangePassword: boolean;
}

const oidcUserToAuthUser = (user: User): IAuthUser => {
	return {
		idToken: user.id_token,
		accessToken: user.access_token,
		refreshToken: user.refresh_token,
		expiresAt: user.expires_at,
		tokenType: user.token_type,
		profile: {
			sub: user.profile.sub,
			iss: user.profile.iss,
			aud: user.profile.aud,
			azp: user.profile.azp,
			name: user.profile.name,
		}
	};
}

@injectable()
export class WebAuthService implements IAuthService {
	loginLogoutConfiguration: UserManagerSettings;
	forgotPasswordConfiguration: UserManagerSettings;
	changePasswordConfiguration: UserManagerSettings;
	tenantName: string;
	tenantId: string;
	baseAuthServerUrl: string;
	clientId: string;
	userStorageString: string;

	constructor(
		{
			baseAuthServerUrl,
			redirectUrl,
			postLogoutUrl,
			clientId,
			forgotPassClientId,
			forgotPassTenantName,
			forgotPassTenantId,
			forgotPassPolicyName,
			changePassPolicyName,
			stateStore,
			enableChangePassword,
			enableForgotPassword,
		}: AuthServiceProps,
		@inject(PersistenceType.IPersistenceService)
		private persistenceService: IPersistenceService,
	) {
		this.baseAuthServerUrl = baseAuthServerUrl;
		this.clientId = clientId;
		this.userStorageString = `oidc.user:${baseAuthServerUrl}:${clientId}`;

		this.loginLogoutConfiguration = createLoginLogoutConfiguration(
			baseAuthServerUrl,
			redirectUrl,
			postLogoutUrl,
			clientId,
			stateStore,
		);

		if (enableForgotPassword) {
			this.forgotPasswordConfiguration = createForgotPasswordConfig(
				baseAuthServerUrl,
				redirectUrl,
				forgotPassTenantId,
				forgotPassClientId,
				forgotPassTenantName,
				forgotPassPolicyName,
				stateStore,
			);
		}

		if (enableChangePassword) {
			this.changePasswordConfiguration = createChangePasswordConfig(
				baseAuthServerUrl,
				redirectUrl,
				forgotPassTenantId,
				forgotPassClientId,
				forgotPassTenantName,
				changePassPolicyName,
				stateStore,
			);
		}

		this.persistenceService = persistenceService;
		Log.setLogger(console);
	}


	getLoginUrl = (): string => {
		return '/login';
	};

	getUserStorageString = (): string => {
		const oidcStorage = localStorage.getItem(this.userStorageString)
		if (!oidcStorage) {
			return null;
		}
		return oidcStorage;
	};

	login = (redirectUrlAfterLogin?: string, provider?: string, userName?: string, signIn?: (args?: SigninRedirectArgs) => Promise<void>) => {
		this.persistenceService.saveLogoutTriggeredFromPortal(false);

		const args: SigninRedirectArgs = {};
		if (redirectUrlAfterLogin) {
			args['state'] = redirectUrlAfterLogin;
		}
		if (provider || userName) {
			const extraQueryParams = {};
			if (provider) {
				extraQueryParams['provider'] = provider;
			}
			if (userName) {
				extraQueryParams['username'] = userName;
			}
			args['extraQueryParams'] = extraQueryParams;

			this.persistenceService.saveProvider(provider);
		}

		return signIn(args);
	};

	renewToken = async (refreshToken?: string | null, renew?: (args?: SigninSilentArgs) => Promise<User>) => {
		if (!refreshToken) {
			throw new Error('refreshToken is required');
		}
		return renew().then(oidcUserToAuthUser)
	}

	signOut = (successCallback: any, finallyCallback?: any,
		provider?: string, signOut?: (args?: SignoutRedirectArgs) => Promise<void>,
		removeUser?: () => Promise<void>) => {
		const logoutRedirectUri = this.loginLogoutConfiguration.post_logout_redirect_uri;
		const extraQueryParams = {};
		if (provider) {
			extraQueryParams['provider'] = provider;
		}

		signOut({
			post_logout_redirect_uri:
				logoutRedirectUri,
			extraQueryParams: extraQueryParams,
		})
			.then(removeUser)
			.then(successCallback)
			.finally(() => {
				this.persistenceService.clearNonPersistentItems();
				this.signalLogout();
				sessionStorage.clear();
				finallyCallback && finallyCallback();
			});
	};

	signalLogout = () => {
		const logoutTriggered =
			this.persistenceService.getLogoutTriggeredFromPortal();
		if (!logoutTriggered) {
			this.persistenceService.saveLogoutTriggeredFromPortal(true);
		}
	};

	logout = (loginPageUrl?: string) => {
		throw new Error('Method not implemented.');
	};

	logoutHard = () => {
		sessionStorage.clear();
		localStorage.clear();
		window.location.href = this.getLoginUrl();
	};

	forgotPassword = (): Promise<void> => {
		const config = this.forgotPasswordConfiguration;
		const mgr2 = new UserManager(config);
		return mgr2.signinRedirect();
	};

	createAccount = (): Promise<void> => {
		const config = createSignUpConfiguration(
			this.baseAuthServerUrl,
			this.loginLogoutConfiguration.redirect_uri,
			this.loginLogoutConfiguration.post_logout_redirect_uri,
			this.clientId,
			this.loginLogoutConfiguration.userStore as WebStorageStateStore,
		);
		const mgr2 = new UserManager(config);
		return mgr2.signinRedirect();
	}

	changePassword = (): Promise<void> => {
		const config = this.changePasswordConfiguration;
		const mgr2 = new UserManager(config);
		return mgr2.signinRedirect();
	};

	getUser = (): Promise<IAuthUser> => {
		return new Promise<IAuthUser>((resolve, reject) => {
			const oidcStorage = localStorage.getItem(`oidc.user:${this.baseAuthServerUrl}:${this.clientId}`)
			const user = User.fromStorageString(oidcStorage);
			if (user) {
				resolve(Promise.resolve(oidcUserToAuthUser(user)));
			} else {
				reject(new Error('User not found.'));
			}
		});
	};
}

const createSignUpConfiguration = (
	baseAuthServerUrl: string,
	redirectUrl: string,
	postLogoutUrl: string,
	clientId: string,
	stateStore: WebStorageStateStore, //<
): UserManagerSettings => {
	if (!baseAuthServerUrl) {
		throw new Error('baseAuthServerUrl is required');
	}
	if (!redirectUrl) {
		throw new Error('redirectUrl is required');
	}
	if (!postLogoutUrl) {
		throw new Error('postLogoutUrl is required');
	}
	if (!clientId) {
		throw new Error('clientId is required');
	}
	const cfg: UserManagerSettings = {
		authority: `${baseAuthServerUrl}/.well-known/openid-configuration`,
		loadUserInfo: false,
		redirect_uri: redirectUrl,
		silent_redirect_uri: redirectUrl,
		response_type: 'code',
		client_id: clientId,
		scope: 'openid murphy_api offline_access',
		post_logout_redirect_uri: postLogoutUrl,
		userStore: stateStore,
		prompt: 'login',
		metadata: {
			issuer: baseAuthServerUrl,
			authorization_endpoint: `${baseAuthServerUrl}/connect/authorize`,
			token_endpoint: `${baseAuthServerUrl}/connect/token`,
			end_session_endpoint: `${baseAuthServerUrl}/connect/logout`,
		},
	};
	return cfg;
};

const createLoginLogoutConfiguration = (
	baseAuthServerUrl: string,
	redirectUrl: string,
	postLogoutUrl: string,
	clientId: string,
	stateStore: WebStorageStateStore, //<
): UserManagerSettings => {
	if (!baseAuthServerUrl) {
		throw new Error('baseAuthServerUrl is required');
	}
	if (!redirectUrl) {
		throw new Error('redirectUrl is required');
	}
	if (!postLogoutUrl) {
		throw new Error('postLogoutUrl is required');
	}
	if (!clientId) {
		throw new Error('clientId is required');
	}
	const cfg: UserManagerSettings = {
		authority: `${baseAuthServerUrl}/.well-known/openid-configuration`,
		loadUserInfo: false,
		redirect_uri: redirectUrl,
		silent_redirect_uri: redirectUrl,
		response_type: 'code',
		client_id: clientId,
		scope: 'openid murphy_api offline_access',
		post_logout_redirect_uri: postLogoutUrl,
		userStore: stateStore,
		prompt: 'login',
		metadata: {
			issuer: baseAuthServerUrl,
			authorization_endpoint: `${baseAuthServerUrl}/connect/authorize`,
			token_endpoint: `${baseAuthServerUrl}/connect/token`,
			end_session_endpoint: `${baseAuthServerUrl}/connect/logout`,
		},
	};
	return cfg;
};

const createForgotPasswordConfig = (
	baseAuthServerUrl: string,
	redirectUrl: string,
	tenantId: string,
	clientId: string,
	tenantName: string,
	policyName: string,
	stateStore: WebStorageStateStore,
): UserManagerSettings => {
	const cfgForgotPass = {
		authority: `${baseAuthServerUrl}/.well-known/openid-configuration`,
		loadUserInfo: false,
		redirect_uri: redirectUrl,
		response_type: 'code',
		client_id: clientId,
		userStore: stateStore,
		prompt: 'login',
		metadata: {
			issuer: `https://${tenantName}.b2clogin.com/${tenantId}/v2.0/`,
			authorization_endpoint: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}/oauth2/v2.0/authorize`,
			token_endpoint: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}/oauth2/v2.0/token`,
			jwks_uri: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/discovery/v2.0/keys?p=${policyName}`,
			end_session_endpoint: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}/oauth2/v2.0/logout`,
		},
	};
	return cfgForgotPass;
};

const createChangePasswordConfig = (
	baseAuthServerUrl: string,
	redirectUrl: string,
	tenantId: string,
	clientId: string,
	tenantName: string,
	policyName: string,
	stateStore: WebStorageStateStore,
): UserManagerSettings => {
	const cfgChangePass = {
		authority: `${baseAuthServerUrl}/.well-known/openid-configuration`,
		loadUserInfo: false,
		redirect_uri: redirectUrl,
		response_type: 'code',
		client_id: clientId,
		scope: `openid https://${tenantName}.onmicrosoft.com/webapi/read https://${tenantName}.onmicrosoft.com/webapi/write`,
		userStore: stateStore,
		metadata: {
			issuer: `https://${tenantName}.b2clogin.com/${tenantId}/v2.0/`,
			authorization_endpoint: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}/oauth2/v2.0/authorize`,
			token_endpoint: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}/oauth2/v2.0/token`,
			jwks_uri: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/discovery/v2.0/keys?p=${policyName}`,
			end_session_endpoint: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}/oauth2/v2.0/logout`,
		},
	};
	return cfgChangePass;
};

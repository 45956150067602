import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  RiCheckFill, RiCloseFill, RiPencilFill, RiDeleteBin7Fill,
} from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import { Constant } from '../../../models/Constant';
import { ColumnGroup, ColumnGroupColumnCell, ColumnGroupRowCell } from '../../../components/ColumnGroupTable/Models';
import { createColumnGroup, getLargeColSizeBasedOnScreen, sortByKey } from '../../../components/ColumnGroupTable/TableUtils';
import TableCellActionDropDownButton from '../../../components/ColumnGroupTable/TableCellActionDropDownButton';
import { getCourseColorClassName, getProductColorClassById } from '@murphy-frontend/common/common';
import ColumnGroupTable from '../../../components/ColumnGroupTable';

export interface UserListViewModel {
  id: string;
  username: string;
  email: string;
  role: number;
  mobile: string;
  deviceToken: string;
  permissions: number[];
  completedCourses?: UserListTrainingSessionViewModel[];
  bookedCourses?: UserListTrainingSessionViewModel[];
  completedExcercises?: UserListTrainingSessionViewModel[];
}

export interface UserListTrainingSessionViewModel {
  id: number;
  date: any;
  trainingTypeId: number;
  trainingSessionStateId: number;
  trainingTag?: string;
}

export interface UserListCourseViewModel {
  id: number;
  name: string;
  displayName: string;
}

interface UsersListProps {
  users: UserListViewModel[];
  allProducts: Constant[];
  allCourses: UserListCourseViewModel[];
  onClickEditUser: (userListViewModel: UserListViewModel) => void;
  onClickDeleteUser: (userListViewModel: UserListViewModel) => void;
  isDeleteUserEnabled: boolean;
  isTrainingEnabled: boolean;
  currentScreenSize: string;
  translations?: Record<string, string>;
}

const UsersList: FunctionComponent<UsersListProps> = ({
  users,
  allProducts,
  allCourses,
  onClickDeleteUser,
  onClickEditUser,
  currentScreenSize,
  translations,
  isDeleteUserEnabled,
  isTrainingEnabled,
}) => {
  const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
  const [maxColumns, setMaxColumns] = useState<number>(0);
  const [currentSortingColumn, setCurrentSortingColumn] =
    useState<string>("username");
  const [sortedAscending, setSortedAscending] = useState<boolean>(true);

  const sortByColumn = (columnKey: string) => {
    setCurrentSortingColumn(columnKey);
    const newSortingOrder = !sortedAscending;
    setSortedAscending(newSortingOrder);
  };

  const generateDropDownListItems = (userRow: UserListViewModel) => {
    const ddlItems = [];

    ddlItems.push(
      <li key="edit" onClick={() => onClickEditUser(userRow)}>
        <div className="vertical-aligner">
          <IconContext.Provider value={{ className: "dropdown-icon" }}>
            <RiPencilFill />
          </IconContext.Provider>
          <span>{translations ? translations.edit : 'Edit'}</span>
        </div>
      </li>
    );

    if (isDeleteUserEnabled) {
      ddlItems.push(
        <li key="delete" onClick={() => onClickDeleteUser(userRow)}>
          <div className="vertical-aligner">
            <IconContext.Provider value={{ className: "dropdown-icon" }}>
              <RiDeleteBin7Fill />
            </IconContext.Provider>
            <span>{translations ? translations.delete : 'Delete'}</span>
          </div>
        </li>
      );
    }

    return ddlItems;
  };
  const createMainColumnGroup = (usersArray: UserListViewModel[]) => {
    const userNameColumns: ColumnGroupColumnCell[] = [
      {
        columnKey: "username",
        displayName: translations ? translations.name : "Name",
        isSortable: true,
        size: getLargeColSizeBasedOnScreen(currentScreenSize),
        onClickColumn: sortByColumn,
      },
    ];

    const userNameRows: ColumnGroupRowCell[][] = usersArray.map((row) => [
      {
        id: `username-${row.id}`,
        rowId: row.id,
        title: row.username,
        displayValue: (
          <TableCellActionDropDownButton
            displayText={row.username}
            listItems={generateDropDownListItems(row)}
            columnSize="column-large"
            onClickRow={() => onClickEditUser(row)}
          />
        ),
        columnKey: "username",
      },
    ]);
    const userNameColumnGroup = createColumnGroup(
      translations ? translations["lang-users"].toUpperCase() : "USERS",
      userNameColumns,
      userNameRows
    );
    return userNameColumnGroup;
  };

  const createUserColumnGroup = (usersArray: UserListViewModel[]) => {
    const userNameColumns: ColumnGroupColumnCell[] = [
      {
        columnKey: "email",
        displayName: translations ? translations.email : "Email",
        isSortable: true,
        size: "column-medium-wide",
        onClickColumn: sortByColumn,
      },
      {
        columnKey: "mobile",
        displayName: translations ? translations["class-lang-modal-actor-phone"] : "Mobile",
        isSortable: false,
        size: "column-medium",
      },
    ];
    const userNameRows = usersArray.map((row) => [
      {
        id: `email-${row.id}`,
        rowId: row.id,
        title: row.email,
        displayValue: row.email,
        columnKey: "email",
      },
      {
        id: `mobile-${row.id}`,
        rowId: row.id,
        displayValue: row.mobile,
        columnKey: "mobile",
      },
    ]);
    const userNameColumnGroup = createColumnGroup(
      translations ? translations.userdata.toUpperCase() : "USER DATA",
      userNameColumns,
      userNameRows
    );
    return userNameColumnGroup;
  };

  const createPermissionsColumnGroup = (
    allCustomerProducts: Constant[],
    usersArray: UserListViewModel[]
  ) => {
    const permissionColumns: ColumnGroupColumnCell[] = allCustomerProducts.map(
      (permission) => ({
        columnKey: permission.id,
        displayName: permission.value,
        isSortable: false,
        size: "column-medium",
      })
    );

    const roleColumn: ColumnGroupColumnCell = {
      columnKey: "role",
      displayName: translations ? translations["lang-label-role"] : "Role",
      isSortable: false,
      size: "column-medium",
    };
    const roleAndPermissionColumns: ColumnGroupColumnCell[] = [
      roleColumn,
      ...permissionColumns,
    ];

    const roleAndPermissionRows: ColumnGroupRowCell[][] = usersArray.map(
      (row) => {
        const customerProductIds = row.permissions;
        const currentCustomerProductCells = allCustomerProducts.map(
          (product) => {
            const customerHasProduct =
              customerProductIds.includes(product.id) === true;
            return {
              id: product.id,
              rowId: row.id,
              displayValue:
                customerHasProduct === true ? (
                  <IconContext.Provider
                    value={{ className: "user-table-icon" }}
                  >
                    <RiCheckFill />
                  </IconContext.Provider>
                ) : (
                  <IconContext.Provider
                    value={{ className: "user-table-icon" }}
                  >
                    <RiCloseFill />
                  </IconContext.Provider>
                ),
              centerContent: true,
              customClass:
                customerHasProduct === true
                  ? getProductColorClassById(product.id)
                  : "user-table-icon-unselected",
              columnKey: product.id,
            };
          }
        );

        currentCustomerProductCells.push({
          id: `role-${row.id}`,
          rowId: row.id,
          displayValue: row.role,
          columnKey: "role",
        });

        return currentCustomerProductCells;
      }
    );

    const roleAndPermissionsColumnGroup = createColumnGroup(
      translations ? translations.permissions.toUpperCase() : "PERMISSIONS",
      roleAndPermissionColumns,
      roleAndPermissionRows
    );
    return roleAndPermissionsColumnGroup;
  };

  const createCoursesColumnGroup = (
    allCoursesArray: UserListCourseViewModel[],
    usersArray: UserListViewModel[]
  ) => {
    const coursesColumns: ColumnGroupColumnCell[] = allCoursesArray.map(
      (course) => ({
        columnKey: course.id,
        displayName: course.displayName,
        isSortable: false,
        size: "column-medium",
      })
    );

    const completedCourses: ColumnGroupRowCell[][] = usersArray.map((row) => {
      const rowCompletedCourses = row.completedCourses.map(
        (completedCourse) => ({
          id: completedCourse.id,
          rowId: row.id,
          displayValue: (
            <IconContext.Provider value={{ className: "user-table-icon" }}>
              <RiCheckFill />
            </IconContext.Provider>
          ),
          customClass: getCourseColorClassName("Courses-Completed"),
          centerContent: true,
          columnKey: completedCourse.id,
        })
      );

      const rowBookedCourses = row.bookedCourses.map((bookedCourse) => ({
        id: bookedCourse.id,
        rowId: row.id,
        displayValue: `${bookedCourse.date}`,
        customClass: getCourseColorClassName("Courses-Booked"),
        centerContent: true,
        columnKey: bookedCourse.id,
      }));

      const rowNotCompletedCourses = allCoursesArray
        .filter(
          (o) =>
            ![...rowCompletedCourses, ...rowBookedCourses].some(
              (i) => i.id === o.id
            )
        )
        .map((notCompletedCourse) => ({
          id: notCompletedCourse.id,
          rowId: row.id,
          displayValue: (
            <IconContext.Provider value={{ className: "user-table-icon" }}>
              <RiCloseFill />
            </IconContext.Provider>
          ),
          customClass: "user-table-icon-unselected",
          centerContent: true,
          columnKey: notCompletedCourse.id,
        }));
      return [
        ...rowCompletedCourses,
        ...rowBookedCourses,
        ...rowNotCompletedCourses,
      ];
    });

    const completedCoursesColumnGroup = createColumnGroup(
      translations ? translations.performedcourses.toUpperCase() : "COURSES",
      coursesColumns,
      completedCourses
    );

    return completedCoursesColumnGroup;
  };

  const createExcercisesColumnGroup = (usersArray: UserListViewModel[]) => {
    const excercisesColumn = [
      {
        columnKey: "excercise",
        displayName: translations ? translations.performedexcercises : "No. of Excercises",
        isSortable: false,
        size: "column-medium",
      },
    ];

    const excercisesRows = usersArray.map((row) => [
      {
        id: row.id,
        rowId: row.id,
        displayValue: row.completedExcercises
          ? String(row.completedExcercises.length)
          : String(0),
        centerContent: true,
        customClass:
          row.completedExcercises && row.completedExcercises.length > 0
            ? getCourseColorClassName("Excercises")
            : "user-table-icon-unselected",
        columnKey: "excercise",
      },
    ]);
    const completedExcercisesColumnGroup = createColumnGroup(
      translations ? translations.excersises.toUpperCase() : "EXCERCISES",
      excercisesColumn,
      excercisesRows
    );
    return completedExcercisesColumnGroup;
  };

  useEffect(() => {
    if (users && allProducts) {
      const allColumnGroups = [];
      const usersCopy = [...users];
      let maxCol = 0;

      if (currentSortingColumn) {
        usersCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
      }

      const mainColGroup = createMainColumnGroup(usersCopy);
      allColumnGroups.push(mainColGroup);

      const userNameColGroup = createUserColumnGroup(usersCopy);
      allColumnGroups.push(userNameColGroup);

      if (allProducts) {
        const roleAndPermissionsColumnGroup = createPermissionsColumnGroup(
          allProducts,
          usersCopy
        );
        allColumnGroups.push(roleAndPermissionsColumnGroup);
        maxCol = maxCol + roleAndPermissionsColumnGroup.columns.length;
      }

      if (
        isTrainingEnabled &&
        allCourses &&
        allCourses.length > 0
      ) {
        const completedCoursesColumnGroup = createCoursesColumnGroup(
          allCourses,
          usersCopy
        );
        allColumnGroups.push(completedCoursesColumnGroup);
        maxCol = maxCol + completedCoursesColumnGroup.columns.length;
      }

      if (isTrainingEnabled) {
        const completedExcercisesColumnGroup =
          createExcercisesColumnGroup(usersCopy);
        allColumnGroups.push(completedExcercisesColumnGroup);
        maxCol = maxCol + completedExcercisesColumnGroup.columns.length;
      }
      maxCol =
        maxCol +
        (userNameColGroup.columns.length + mainColGroup.columns.length);

      setMaxColumns(maxCol);
      setColumnGroups(allColumnGroups);
    }
  }, [
    users,
    allProducts,
    allCourses,
    sortedAscending,
    currentSortingColumn,
    translations,
    currentScreenSize,
  ]);

  return (
    <ColumnGroupTable
      maxColumns={maxColumns}
      columngroups={columnGroups}
      sortedByColumn={currentSortingColumn}
      isSortedAscending={sortedAscending}
      translations={translations}
    />
  );
};

export default UsersList;

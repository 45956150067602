import React from "react";
import { FunctionComponent } from "react";
import { SimpleResponsiveListColumnModel, SimpleResponsiveListRowModel } from "./models";

interface SimpleResponsiveListRowProps {
    row: SimpleResponsiveListRowModel,
    columns: SimpleResponsiveListColumnModel[],
    handleClick: (id: string) => void,
    gridStyle: any,
    clickable: boolean,
}

const SimpleResponsiveListRow: FunctionComponent<SimpleResponsiveListRowProps> = (
    {
        row,
        columns,
        handleClick,
        gridStyle,
        clickable,
    }
) => {
    let listClass = 'item item-container';
    if (row.clickable) {
        listClass += ' clickable';
    }
    return (

        <li id={row.id}
            key={row.id}
            className={listClass}
            style={gridStyle}
            onClick={clickable ? () => handleClick(row.id) : () => { }}
        // onKeyUp={handleClick}
        >
            {columns.map((col) => {
                if (row[col.key]) {
                    return (<div className="attribute" key={col.key} data-name={col.key} title={row[col.key]}>{row[col.key]}</div>);
                }
                return (<div className="attribute" key={col.key} data-name={col.key} title={row[col.key]} />);
            })}
        </li>
    );
}

export default SimpleResponsiveListRow;

import * as React from 'react';
import { FunctionComponent } from 'react';

interface TabProps {
  id: any,
  displayText: string,
  isSelected: boolean,
  onClickTab: (id: number) => void,
  customClass?: string,
  customSelectedClass?: string,
  overrideClass?: string
}

const Tab: FunctionComponent<TabProps> = ({ id, displayText, isSelected, onClickTab, customClass, customSelectedClass, overrideClass }) => {
  let className = 'tab';
  if (customClass) {
    className += ` ${customClass}`;
  }
  if (isSelected === true) {
    if (customSelectedClass) {
      className += ` ${customSelectedClass}`;
    } else {
      className += ' tab-selected';
    }
  }

  return (
    <div className={className} onClick={() => onClickTab(id)}>
      <h4>{displayText}</h4>
    </div>
  );
}

export default Tab;
import { parsePhoneNumberWithError, ParseError, isValidPhoneNumber } from 'libphonenumber-js';

export const parseMobileNumber = (mobilePhone: string, successCb: (mobilePhone: string) => void, failCb: (error: ParseError) => void) => {
  try {
    const parsedNumber = parsePhoneNumberWithError(mobilePhone);
    if (!isValidPhoneNumber(mobilePhone)) {
      throw new ParseError();
    }
    successCb(parsedNumber.number);
  } catch (error) {
    if (error instanceof ParseError) {
      failCb(error);
    }
  }
};

export const emailIsValid = (email: string): boolean => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const userNameIsValid = (userName: string): boolean => {
  const myRegEx = /^[a-zA-Z0-9ÅÄÖåäö._@ ]{3,}$/;
  return myRegEx.test(userName);
};

// todo: validate?
export const dashboardNameIsValid = (dashboardName: string) => true;

export const isValidWindowsFileName = (fname: string): boolean => {
  const rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
  const rg2 = /^\./; // cannot start with dot (.)
  const rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
  return rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname);
};

import { useState, useEffect, FunctionComponent } from 'react';
import { isValidWindowsFileName } from '@murphy-frontend/common/validation';
import * as React from 'react';
import Input from './Input';
import Button from './Button';
import Spinner from './Spinner';

interface EditFileProps {
  id: any,
  nameInit?: string,
  onSuccess?: () => void,
  onFail?: () => void,
  translations?: Record<string, string>,
  onSaveEdit?: any,
  submitOverride?: (editFileRequest: EditFileRequest) => void,
}

export interface EditFileRequest {
  NewName: string,
  Id: string,
}

const EditFile: FunctionComponent<EditFileProps> = ({
  id,
  nameInit,
  onSuccess,
  onFail,
  translations,
  onSaveEdit,
  submitOverride,
}) => {
  const [name, setName] = useState(nameInit);
  const [extension, setExtension] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const onNameChanged = (event) => {
    const newName = event.target.value;
    if (!newName) {
      setNameErrorMessage("");
    } else {
      const newExtension = newName.split(".").pop();
      if (newExtension !== extension) {
        setNameErrorMessage(`The file extension must be .${extension}`);
      } else if (!isValidWindowsFileName(newName)) {
        setNameErrorMessage("Invalid filename");
      } else {
        setNameErrorMessage("");
      }
    }
    setName(newName);
  };

  const submit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (nameErrorMessage) {
      return;
    }

    const editFileRequest: EditFileRequest = {
      NewName: name,
      Id: id,
    };

    if (submitOverride) {
      submitOverride(editFileRequest);
    } else {
      onSaveEdit(editFileRequest, {
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onFail();
        },
      });
    }
  };

  useEffect(() => {
    if (nameInit) {
      setName(nameInit);
      const oldExtension = nameInit.split('.').pop();
      setExtension(oldExtension);
    }
  }, [nameInit]);

  const buttonEnabled = !nameErrorMessage;

  return (
    <form className="modal-form">
      <Input title={translations ? translations.name : "Name"} val={name} isRequired handleChange={(event) => onNameChanged(event)} errorMessage={nameErrorMessage} />
      <div className="modal-footer">
        <Button isLoading={isLoading} disabled={!buttonEnabled} type="submit" buttonStyle="btn--primary" buttonSize="btn--medium" onClick={submit}>
          {translations ? translations.save : "Save"}
        </Button>
      </div>
    </form>
  );
}

export default EditFile;

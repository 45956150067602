import { useState, useEffect } from 'react';
import { screenSizes } from '../constants';

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;

    const compatibleSizes = screenSizes.filter((size) => width < size.value);
    let currentSize = 'xl';
    if (compatibleSizes && compatibleSizes.length > 0) {
      currentSize = compatibleSizes[0].id;
    }
    return {
      width,
      height,
      currentSize,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}

import { ILogService } from "./ILogService";

export class AppInsightsLogService implements ILogService {

    // appInsights: ReactPlugin;

    logError = (error: Error, errorInfo: any): void => {
        // appInsights.trackException({
        //     error,
        //     exception: error,
        //     severityLevel: SeverityLevel.Error,
        //     properties: errorInfo,
        //   });
    }

}
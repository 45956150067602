import { IconContext } from 'react-icons/lib';
import {
  AiOutlinePlus,
} from 'react-icons/ai';
import {
  RiDeleteBin7Fill, RiInboxUnarchiveLine, RiInboxArchiveFill,
} from 'react-icons/ri';
import {
  GrFormNext, GrFormPrevious,
} from 'react-icons/gr';
import {
  IoReturnUpBackOutline,
} from 'react-icons/io5';
import {
  BiUpload, BiUndo,
} from 'react-icons/bi';
import { FunctionComponent } from 'react';
import React from 'react';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test', 'btn--add', 'btn--remove', 'btn--undo',
  'btn--back', 'btn--next', 'btn--prev', 'btn--archive', 'btn--unarchive', 'btn--upload'];
const SIZES = ['btn--medium', 'btn--large', 'btn--extra-large-square'];

interface ButtonProps {
  type?: "button" | "submit" | "reset",
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  buttonStyle?: string,
  buttonSize?: string,
  isIconButton?: boolean,
  isIconAndTextButton?: boolean,
  isNegativeButton?: boolean,
  isWarningButton?: boolean,
  isNeutralButton?: boolean,
  disabled?: boolean,
  isLoading?: boolean,
  children?: React.ReactNode,
  title?: string,
  icon?: React.ReactNode,
}

const Button: FunctionComponent<ButtonProps> = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  isIconButton,
  isIconAndTextButton,
  isNegativeButton,
  isWarningButton,
  isNeutralButton,
  disabled,
  isLoading,
  title,
  icon,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  let allStyles = `btn ${checkButtonStyle} ${checkButtonSize} vertical-aligner`;

  if (isIconButton === true) {
    allStyles += ' btn-with-icon';
  }

  if (isNegativeButton === true) {
    allStyles += ' btn-negative';
  } else if (isWarningButton === true) {
    allStyles += ' btn-warning';
  } else if (isNeutralButton === true) {
    allStyles += ' btn-neutral';
  } else {
    allStyles += ' btn-positive';
  }

  let commonIconStyles = 'btn-icon-container vertical-aligner horizontal-aligner';

  if (isIconAndTextButton === true) {
    commonIconStyles += ' btn-with-icon';
  }

  return (
    <button
      title={title}
      className={allStyles}
      onClick={onClick}
      type={type ?? "button"}
      disabled={disabled || isLoading}
    >
      {isLoading === true
        && <div className='btn-icon-container vertical-aligner horizontal-aligner'>
          <div className="small-spinner" />
        </div>}
      {children}
      {icon && <div className={commonIconStyles}>{icon}</div>}
      {buttonStyle === 'btn--add' ? (
        <div className={commonIconStyles}>
          <IconContext.Provider value={{ className: 'btn-icon' }}>
            <AiOutlinePlus />
          </IconContext.Provider>
        </div>
      ) : null}
      {buttonStyle === 'btn--remove' ? (
        <div className={`${commonIconStyles} btn-negative`}>
          <IconContext.Provider value={{ className: 'btn-icon' }}>
            <RiDeleteBin7Fill />
          </IconContext.Provider>
        </div>
      ) : null}
      {buttonStyle === 'btn--next' ? (
        <div className={commonIconStyles}>
          <IconContext.Provider value={{ className: 'btn-icon' }}>
            <GrFormNext />
          </IconContext.Provider>
        </div>
      ) : null}
      {buttonStyle === 'btn--prev' ? (
        <div className={commonIconStyles}>
          <IconContext.Provider value={{ className: 'btn-icon' }}>
            <GrFormPrevious />
          </IconContext.Provider>
        </div>
      ) : null}
      {buttonStyle === 'btn--back' ? (
        <div className={commonIconStyles}>
          <IconContext.Provider value={{ className: 'btn-icon' }}>
            <IoReturnUpBackOutline />
          </IconContext.Provider>
        </div>
      ) : null}
      {buttonStyle === 'btn--archive' ? (
        <div className={commonIconStyles}>
          <IconContext.Provider value={{ className: 'btn-icon' }}>
            <RiInboxArchiveFill />
          </IconContext.Provider>
        </div>
      ) : null}
      {buttonStyle === 'btn--unarchive' ? (
        <div className={commonIconStyles}>
          <IconContext.Provider value={{ className: 'btn-icon' }}>
            <RiInboxUnarchiveLine />
          </IconContext.Provider>
        </div>
      ) : null}
      {buttonStyle === 'btn--upload' ? (
        <div className={commonIconStyles}>
          <IconContext.Provider value={{ className: 'btn-icon' }}>
            <BiUpload />
          </IconContext.Provider>
        </div>
      ) : null}
      {buttonStyle === 'btn--undo' ? (
        <div className={commonIconStyles}>
          <IconContext.Provider value={{ className: 'btn-icon' }}>
            <BiUndo />
          </IconContext.Provider>
        </div>
      ) : null}
    </button>
  );
}

export default Button;

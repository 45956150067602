import { AxiosPromise, AxiosResponse, Method, ResponseType } from 'axios';
import { IAuthUser } from './IAuthService';

export interface IApiService {
    callApiWithToken: (token: string, url: string, method: Method, sendData: any, overrideHeaders: any, overrideResponseType: ResponseType) => AxiosPromise<any>;
    callApiWithoutToken: (url: string, method: Method, sendData?: any, overrideHeaders?: any, overrideResponseType?: ResponseType) => AxiosPromise<any>;
    callApi: (url: string, method: Method, sendData?: any, overrideHeaders?: any, overrideResponseType?: any) => Promise<AxiosResponse<any>>;
    callOrRenew: (user: IAuthUser, url: string, method: Method, sendData?: any, overrideHeaders?: any, overrideResponseType?: any) => Promise<AxiosResponse<any, any>>;
    getLangTranslations: (lang: string) => AxiosPromise<any>;
    downloadExcel: (dashboardId: string, lang: string, utcOffsetInMinutes: number) => Promise<AxiosResponse<any, any>>;
    addFileToCustomer: (fileToUpload: Blob, customerId: string) => Promise<AxiosResponse<any, any>>;
    getFile: (id: string) => Promise<AxiosResponse<any, any>>;
}

let ApiServiceType = {
    IApiService: Symbol("IApiService"),
};

export default ApiServiceType;
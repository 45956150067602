import {
  RiCheckboxBlankLine, RiCheckboxLine,
} from 'react-icons/ri';
import { IconContext } from 'react-icons';
import FilterBox from './FilterBox';
import AddFilterDropdown from './AddFilterDropdown';
import { FunctionComponent } from 'react';
import * as React from 'react';

export interface FilterType {
  displayName: string,
  columnname: string,
  filtervalue: any,
  isActive: boolean,
}

interface FilterControlProps {
  allFilters: FilterType[],
  onToggleFilter: (filter: FilterType) => void,
  showReset?: boolean,
  onClickResetFilters?: () => void,
  translations?: Record<string, string>,
  addFilterButtonText?: string
  showMarkAll?: boolean,
  onToggleMarkAll?: () => void,
  markAllIsToggled?: boolean
}

const FilterControl: FunctionComponent<FilterControlProps> = ({
  addFilterButtonText,
  allFilters,
  onToggleFilter,
  translations,
  showReset,
  onClickResetFilters,
  showMarkAll,
  onToggleMarkAll,
  markAllIsToggled }) => {

  const markAllFilter = <li key="markall" onClick={() => onToggleMarkAll()}>
    <div className="vertical-aligner">
      <IconContext.Provider value={{ className: 'recipient-item-icon' }}>
        {markAllIsToggled ? <RiCheckboxLine /> : <RiCheckboxBlankLine />}
      </IconContext.Provider>
      <span className="recipient-item-text">{translations ? translations.markall : "Mark all"}</span>
    </div>
  </li>

  const filterComponents = allFilters.map((filter) => (
    <li key={filter.displayName} onClick={() => onToggleFilter(filter)}>
      <div className="vertical-aligner">
        <IconContext.Provider value={{ className: 'recipient-item-icon' }}>
          {filter.isActive ? <RiCheckboxLine /> : <RiCheckboxBlankLine />}
        </IconContext.Provider>
        <span className="recipient-item-text">{filter.displayName}</span>
      </div>
    </li>
  ));

  // const allFilterComponents = showMarkAll ? filterComponents.reverse().push(markAllFilter) : filterComponents;
  if (showMarkAll)
    filterComponents.unshift(markAllFilter);

  const resetButton = <div onClick={onClickResetFilters} className="filter-control-box add-filter-box filter-clear-color">
    <div className="filter-control-box-text">
      {translations ? translations.reset : "Reset"}
    </div>
  </div>;

  const allFilterBoxes = allFilters.filter((f) => f.isActive === true).map((filter) => (
    <FilterBox key={filter.displayName} filterName={filter.displayName} onClickRemoveFilter={() => onToggleFilter(filter)} />));

  return (
    <div className="filter-control-container">
      {showReset === true && resetButton}
      <AddFilterDropdown
        buttonText={addFilterButtonText}
        translations={translations}
        filters={filterComponents}
      />
      {allFilterBoxes}
    </div>);
}

export default FilterControl;

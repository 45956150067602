import React, { FunctionComponent, useRef } from 'react';
import { Link } from 'react-router-dom';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';

interface DropdownMenuProps {
    title: JSX.Element,
    items?: any[],
    externalLinks?: any[],
    onClickItem: () => void,
    customClass?: string,
}

const NavDropdownMenu: FunctionComponent<DropdownMenuProps> = ({
    title, items, externalLinks, onClickItem, customClass,
}) => {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => {
        setIsActive(!isActive);
    };

    let linkClass = 'nav-links';
    if (isActive) {
        linkClass = 'nav-links nav-links-clicked';
    }

    if (customClass) {
        linkClass += ` ${customClass}`;
    }

    return (
        <>
            <div>
                <div
                    role="button"
                    className={linkClass}
                    tabIndex={-1}
                    onClick={onClick}
                    onKeyUp={onClick}
                >
                    {title}
                </div>

                <ul className={isActive ? 'nav-menu-dropdown active' : 'nav-menu-dropdown'}>
                    {items ? items.map((p) => (
                        <li key={p.url} className="nav-item">
                            <Link
                                to={p.url}
                                className="nav-links"
                                onClick={onClickItem}
                            >
                                {p.displayName}
                            </Link>
                        </li>
                    )) : null}
                    {externalLinks ? externalLinks.map((q) => (
                        <li key={q.url} className="nav-item">
                            <a
                                href={q.url}
                                className="nav-links"
                                onClick={onClickItem}
                            >
                                {q.displayName}
                            </a>
                        </li>
                    )) : null}
                </ul>
            </div>

        </>
    );
}

export default NavDropdownMenu;

import React, { FunctionComponent, useState } from 'react';
import {
  FaRegCheckCircle, FaRegCircle, FaSms, FaEnvelope, FaBell
} from 'react-icons/fa';
import { IconContext } from 'react-icons';

interface CommunicationTypeFilterProps {
  onCheckAllMobile: () => void;
  onCheckAllEmail: () => void;
  onCheckAllNotification: () => void;
  checkAllMobileChecked: boolean;
  checkAllEmailChecked: boolean;
  checkAllNotificationChecked: boolean;
  smsDisabled?: boolean;
  size?: 'small' | 'large';
  translations?: Record<string, string>;
  notificationDisabled?: boolean;
  emailDisabled?: boolean;
}

const CommunicationTypeFilter: FunctionComponent<
  CommunicationTypeFilterProps
> = ({
  onCheckAllMobile,
  onCheckAllEmail,
  onCheckAllNotification,
  checkAllMobileChecked,
  checkAllEmailChecked,
  checkAllNotificationChecked,
  smsDisabled,
  size,
  translations,
  notificationDisabled,
  emailDisabled,

}) => {
    const buttonClass = size === "small" ?
      "recipients-select-button-small recipients-select-button-markall" :
      "recipients-select-button recipients-select-button-markall";
    const buttonClassUnSelected = size === "small" ?
      "recipients-select-button-small recipients-button-unselected" :
      "recipients-select-button recipients-button-unselected";

    const buttonContainerClass = size === "small" ?
      "recipient-item-button-container-small" :
      "recipient-item-button-container";

    const iconClass = size === "small" ?
      "recipient-item-icon-small" :
      "recipient-item-icon";

    return (
      <>
        <ol>
          <li className="recipient-list-item" key="checkMobileEmailNotifcation">
            <div className={buttonContainerClass}>
              {!smsDisabled && (
                <div
                  className={
                    checkAllMobileChecked ? buttonClass : buttonClassUnSelected
                  }
                  onClick={onCheckAllMobile}
                  title="SMS"
                >
                  <IconContext.Provider
                    value={{ className: iconClass }}
                  >
                    {checkAllMobileChecked ? (
                      <FaRegCheckCircle />
                    ) : (
                      <FaRegCircle />
                    )}
                  </IconContext.Provider>
                  &nbsp;&nbsp;
                  <IconContext.Provider
                    value={{ className: iconClass }}
                  >
                    <FaSms />
                  </IconContext.Provider>
                </div>
              )}
              {!notificationDisabled && <div
                className={
                  checkAllNotificationChecked
                    ? `${buttonClass} recipient-item-button-container-border-left`
                    : `${buttonClassUnSelected} recipient-item-button-container-border-left`
                }
                onClick={onCheckAllNotification}
                title="Notification"
              >
                <IconContext.Provider
                  value={{ className: iconClass }}
                >
                  {checkAllNotificationChecked ? (
                    <FaRegCheckCircle />
                  ) : (
                    <FaRegCircle />
                  )}
                </IconContext.Provider>
                &nbsp;&nbsp;
                <IconContext.Provider
                  value={{ className: iconClass }}
                >
                  <FaBell />
                </IconContext.Provider>
              </div>}

              {!emailDisabled && <div
                className={
                  checkAllEmailChecked
                    ? `${buttonClass} recipient-item-button-container-border-left`
                    : `${buttonClassUnSelected} recipient-item-button-container-border-left`
                }
                onClick={onCheckAllEmail}
                title={translations?.email ?? "Email"}
              >
                <IconContext.Provider
                  value={{ className: iconClass }}
                >
                  {checkAllEmailChecked ? <FaRegCheckCircle /> : <FaRegCircle />}
                </IconContext.Provider>
                &nbsp;&nbsp;
                <IconContext.Provider
                  value={{ className: iconClass }}
                >
                  <FaEnvelope />
                </IconContext.Provider>
              </div>}
            </div>
          </li>
        </ol>
      </>
    );
  };

export default CommunicationTypeFilter;

import React, { useState, useContext, createContext, useEffect } from 'react';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import PersistenceType, {
	IPersistenceService,
	LocalStorageKeys,
} from '@murphy-frontend/common/interfaces/IPersistenceService';
import AuthServiceType, {
	IAuthService, IAuthUser,
} from '@murphy-frontend/common/interfaces/IAuthService';
import { useAuth as useOidcAuth, hasAuthParams } from "react-oidc-context";
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { SigninSilentArgs } from 'oidc-client-ts';

interface WebAuthContextType {
	signIn: (redirectUrlAfterLogin?: string, provider?: string, userName?: string) => void;
	signOut: () => void;
}

const WebAuthContext = createContext<WebAuthContextType | undefined>(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function WebAuthProvider({ children }: { children: React.ReactNode }) {
	const navigate = useNavigate();
	const oidcAuth = useOidcAuth();
	const location = useLocation();
	const excludedPaths = ['/login', '/pbsresponse/', '/training/', '/signup'];
	const loadingSpinner = <div className="spinner-container-global-center"><Spinner /></div>;

	const persistenceService = useInjection<IPersistenceService>(
		PersistenceType.IPersistenceService,
	);
	const authService = useInjection<IAuthService>(
		AuthServiceType.IAuthService,
	);

	const signIn = (redirectUrlAfterLogin?: string, provider?: string, userName?: string) => {
		var provider = provider?.toLocaleUpperCase();
		authService.login(redirectUrlAfterLogin, provider, userName, oidcAuth.signinRedirect)
			.then(() => {
				persistenceService.saveLogoutTriggeredFromPortal(false);
			});
	};

	const signOut = () => {
		const provider = persistenceService.getProvider()?.toLocaleUpperCase();
		authService.signOut(
			() => {
				sessionStorage.clear();
				navigate('/login');
			},
			undefined,
			provider,
			oidcAuth.signoutRedirect,
			oidcAuth.removeUser);
	};

	useEffect(() => {
		// the `return` is important - addAccessTokenExpiring() returns a cleanup function
		return oidcAuth.events.addUserUnloaded(() => {
			persistenceService.saveLogoutTriggeredFromPortal(true);
			navigate('/login');
		})
	}, [oidcAuth.events, oidcAuth.removeUser]);

	useEffect(() => {
		// Respond to the `storage` event
		function storageEventHandler(event: StorageEvent) {
			if (event.key === LocalStorageKeys.LogoutTriggeredFromPortal) {
				const logoutTriggered = persistenceService.getLogoutTriggeredFromPortal();
				if (logoutTriggered === true) {
					window.location.href = '/login';
				}
			}
		}
		// Hook up the event handler
		window.addEventListener('storage', storageEventHandler);
		return () => {
			// Remove the handler when the component unmounts
			window.removeEventListener('storage', storageEventHandler);
		};
	}, []);

	if (!excludedPaths.some(path => location.pathname.startsWith(path))) {
		if (oidcAuth.error) {
			navigate('/login');
		}

		if (oidcAuth.isLoading) {
			return loadingSpinner;
		}

		if (!oidcAuth.isAuthenticated) {
			const args: SigninSilentArgs = {};
			const extraQueryParams = {};
			const provider = persistenceService.getProvider();
			if (provider) {
				extraQueryParams['provider'] = provider;
				args['extraQueryParams'] = extraQueryParams;
			}

			oidcAuth.signinSilent(args).catch(() => {
				navigate('/login');
			});
		}

		switch (oidcAuth.activeNavigator) {
			case 'signinSilent':
				return loadingSpinner;
			case 'signoutRedirect':
				return loadingSpinner;
			case 'signinRedirect':
				return loadingSpinner;
			// Your code here
			// ...
		}
	}

	const value = {
		signIn,
		signOut,
	};

	return (
		<WebAuthContext.Provider value={value}>{children}</WebAuthContext.Provider>
	);
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	const context = useContext(WebAuthContext);
	if (!context) throw new Error('AuthContext Provider not found');
	return context as WebAuthContextType;
};

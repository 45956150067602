import React, {
  useState, useContext, createContext,
} from 'react';
import Modal, { ModalConfiguration } from '../components/Modal';

interface ModalContextType {
  openModal: (configuration: ModalConfiguration) => void,
  closeModal: () => void,
  toggleModal: () => void,
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export function ModalProvider({ children }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfiguration, setModalConfiguration] = useState({});

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = (modalConf: ModalConfiguration) => {
    if (modalConf) {
      setModalConfiguration(modalConf);
    }
    setModalOpen(true);
  };

  const value = {
    openModal, closeModal, toggleModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {modalOpen && (
        <Modal configuration={modalConfiguration} toggleModal={toggleModal} />
      )}
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);

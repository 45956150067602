import {
  RiCloseFill,
} from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import { getProductColorClassById } from '../../../common/common';
import React, { FunctionComponent } from 'react';
import { Constant } from '../../models/Constant';

interface PermissionsSelectorProps {
  allProducts: Constant[],
  selectedProductIds: number[],
  onSelectProduct: (productId: number) => void,
  isReadonly: boolean,
}

const PermissionsSelector: FunctionComponent<PermissionsSelectorProps> = ({
  allProducts,
  selectedProductIds,
  onSelectProduct,
  isReadonly,
}) => {
  return (
    <div>
      <ul className="permission-selector">
        {allProducts.map((product) => {
          const matchingUserPermission = selectedProductIds.filter((prod) => prod === product.id);

          let iconContainerClasses = 'vertical-aligner horizontal-aligner';
          if (matchingUserPermission.length > 0) {
            const courseColorClass = getProductColorClassById(product.id);
            iconContainerClasses += ` ${courseColorClass}`;
          } else {
            iconContainerClasses += ' unselected-module-color';
          }

          return (
            <li key={product.id}>
              <div onClick={isReadonly === false ? () => onSelectProduct(product.id) : () => { }} className="permission-selector-row vertical-aligner">
                <span className="vertical-aligner">{product.value}</span>
                {' '}
                <div className={iconContainerClasses}>
                  <IconContext.Provider value={{ className: 'user-table-icon' }}>
                    <RiCloseFill />
                  </IconContext.Provider>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  )
}

export default PermissionsSelector;

import React from "react";
import { FunctionComponent } from "react";
import { IconContext } from "react-icons/lib";
import { TbError404 } from 'react-icons/tb'

const NotFound: FunctionComponent = () => {
    return (
        <div className="not-found-page horizontal-aligner vertical-aligner">
            <br />
            <br />

            <h1>
                UH OH, PAGE NOT FOUND
            </h1>
            <br />
            <IconContext.Provider value={{ className: 'not-found-icon' }}>
                <TbError404 />
            </IconContext.Provider>
        </div>
    );
}

export default NotFound;
import React, { FunctionComponent } from 'react';

interface FilterBoxProps {
  filterName: string,
  onClickRemoveFilter: () => void
}

const FilterBox: FunctionComponent<FilterBoxProps> = ({ filterName, onClickRemoveFilter }) => {
  return (
    <div onClick={onClickRemoveFilter} className="filter-control-box">
      <div className="filter-control-box-text">
        {filterName}
      </div>
      <div className="filter-control-box-remove-button">x</div>
    </div>
  );
}

export default FilterBox;

import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { IconContext } from 'react-icons/lib';
import { MdClose } from 'react-icons/md';

interface DashboardSearchProps {
    value: string,
    setValue: (val: string) => void,
    placeholder: string
    onClickClear: () => void,
}

const DashboardSearch: FunctionComponent<DashboardSearchProps> = ({ value, setValue, placeholder, onClickClear: onClickClear, }) => {

    const onChangeVal = (evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault();
        const newVal = evt.target.value;
        setValue(newVal);
    }

    return (
        <div className='dashboard-table-row'>
            <div className='dashboard-search-bar-murphy vertical-aligner'>
                <input
                    className='dashboard-table-search'
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => onChangeVal(e)}
                    autoComplete="off"
                />

                <div className="dashboard-search-inner-icon vertical-aligner horizontal-aligner" onClick={() => onClickClear()}>
                    <IconContext.Provider value={{ className: 'dashboard-search-bar-icon' }}>
                        <MdClose />
                    </IconContext.Provider>
                </div>

            </div>
        </div>

    );
}

export default DashboardSearch;
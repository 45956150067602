import React, { FunctionComponent } from 'react';

interface SpinnerProps {
  isGlobal?: boolean;
}

const Spinner: FunctionComponent<SpinnerProps> = ({ isGlobal }) => {

  if (isGlobal === true) {
    return <div className="spinner-container-global-center"><div className="spinner" /></div>
  }

  return <div className="spinner" />;
}

export default Spinner;

import { useRef, useEffect, FunctionComponent } from 'react';
import { IconContext } from 'react-icons/lib';
import {
  RiAccountCircleLine,
  RiLogoutBoxLine,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import OptionSet from '../OptionSet';
import { truncate } from '@murphy-frontend/common/utils';
import React from 'react';
import { useAuth } from '../../features/auth/WebAuthProvider';

export interface CustomerMenuModel {
  Id: string;
  Name: string;
}

interface UserProfileMenuCardProps {
  userName: string,
  customerName: string,
  isClicked: boolean,
  setIsClicked: (isClicked: boolean) => void,
  allLicences: CustomerMenuModel[],
  currentCustomerId: string,
  onLicenseChange: (event: any) => void,
  translations?: Record<string, string>,
  darkMode?: boolean,
}

const UserProfileMenuCard: FunctionComponent<UserProfileMenuCardProps> =
  ({
    userName,
    customerName,
    isClicked,
    setIsClicked,
    allLicences,
    currentCustomerId,
    onLicenseChange,
    translations,
    darkMode,
  }) => {
    const userProfileRef = useRef(null);
    const [clickAdmin, setClickAdmin] = useDetectOutsideClick(userProfileRef, false);
    const auth = useAuth();

    useEffect(() => {
      setClickAdmin(isClicked);
    }, [isClicked]);

    const cardClass = clickAdmin === true ? 'profile-card-content active' : 'profile-card-content';
    let navBarItemClass = clickAdmin === true ? 'profile-card menu-icon-selected' : 'profile-card';
    if (darkMode === true) {
      navBarItemClass = navBarItemClass.concat(' dark-mode');
    }

    let iconClass = clickAdmin === true ? 'react-icons menu-icon-selected' : 'react-icons';
    if (darkMode === true) {
      iconClass = iconClass.concat(' dark-mode');
    }

    const allLicensesMapped = allLicences && allLicences.length > 0 ? allLicences.map((lic) => ({
      id: lic.Id,
      value: truncate(lic.Name, 25),
    })).sort((a, b) => (a.value.toUpperCase() > b.value.toUpperCase()) - (a.value.toUpperCase() < b.value.toUpperCase())) : [];

    const onClickLogout = () => {
      auth.signOut();
    };

    const onClickAnyLink = () => {
      setIsClicked(!isClicked);
    };

    return (
      <div>
        <div onClick={setIsClicked} onKeyUp={setIsClicked} className={navBarItemClass}>
          <div className="card-name">
            <div>
              {truncate(userName, 25)}
            </div>
            <div>
              {truncate(customerName, 20)}
            </div>
          </div>
          <div className="card-icon" role="button" tabIndex="0">
            <IconContext.Provider value={{ className: iconClass }}>
              <RiAccountCircleLine />
            </IconContext.Provider>
          </div>

        </div>

        <div className={cardClass} ref={userProfileRef}>
          <div className="profile-card-dropdown">
            <div className="profile-card-dropdown-first-row">
              <div className="profile-card-logout-button" onClick={onClickLogout}>
                <span>{translations ? translations['lang-logga-ut'] : 'Logga ut'}</span>
                <IconContext.Provider value={{ className: 'profile-card-dropdown-icon' }}>
                  <RiLogoutBoxLine />
                </IconContext.Provider>
              </div>
            </div>
            <div>
              <div>
                <b>{truncate(userName, 25)}</b>
              </div>
              <div>
                <b>{truncate(customerName, 50)}</b>
              </div>
            </div>
            <div>
              <Link
                to="/me"
                onClick={onClickAnyLink}
              >
                <span>{translations ? translations.myprofile : 'Min profil'}</span>
              </Link>
            </div>

            {allLicensesMapped.length > 1 ? (
              <div className="profile-card-optionset">
                <OptionSet
                  optionSetName={translations ? translations.license : 'Licens'}
                  direction="column"
                  key={1}
                  optionSetId={1}
                  options={allLicensesMapped}
                  selectedOption={currentCustomerId}
                  handleChange={onLicenseChange}
                />
              </div>
            ) : null}

          </div>
        </div>
      </div>
    );
  }

export default UserProfileMenuCard;

import React, { FunctionComponent } from 'react';
import { ColumnGroupRowCell } from './Models';

interface TableRowCellProps {
  rowCell: ColumnGroupRowCell,
  children: React.ReactNode
}

const TableRowCell: FunctionComponent<TableRowCellProps> = ({
  rowCell, children
}) => {
  let classes = 'row-cell';

  if (rowCell.size) {
    classes += ` ${rowCell.size}`;
  }

  if (rowCell.centerContent === true) {
    classes += ' horizontal-aligner';
  }

  if (rowCell.customClass) {
    classes += ` ${rowCell.customClass}`;
  }

  return (
    <div key={rowCell.id} className={classes} title={rowCell.title}>
      {children}
    </div>
  );
}

export default TableRowCell;

import { FunctionComponent, useEffect, useState } from 'react';
import {
  RiPencilFill, RiDeleteBin7Fill, RiDownload2Line,
} from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import * as React from 'react';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { createColumnGroup, getUserTableSpanClass, sortByKey } from './ColumnGroupTable/TableUtils';
import TableCellActionDropDownButton from './ColumnGroupTable/TableCellActionDropDownButton';
import ColumnGroupTable from './ColumnGroupTable';

export interface FileListFile {
  id: any,
  filename: string,
  createddatetime: string,
  fileId?: string,
  filetypename?: string,
  isEditable: boolean,
  onClickDownloadOverride?: () => void,
}

interface FileListProps {
  files: FileListFile[]
  onClickEditFile?: (file: FileListFile) => void,
  onClickRemoveFile?: (file: FileListFile) => void,
  isReadOnly?: boolean
  hidePageInputs?: boolean,
  hideFooter?: boolean,
  translations?: Record<string, string>,
}

const FileList: FunctionComponent<FileListProps> = (
  { files,
    onClickEditFile,
    onClickRemoveFile,
    isReadOnly,
    hidePageInputs,
    hideFooter,
    translations
  }
) => {
  const [columnGroups, setColumnGroups] = useState([]);
  const [currentSortingColumn, setCurrentSortingColumn] = useState('createddatetime');
  const [sortedAscending, setSortedAscending] = useState(false);

  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);

  const sortByColumn = (columnKey) => {
    setCurrentSortingColumn(columnKey);
    const newSortingOrder = !sortedAscending;
    setSortedAscending(newSortingOrder);
  };

  const onClickDownloadFile = (fileRow: FileListFile) => {

    if (fileRow.onClickDownloadOverride) {
      fileRow.onClickDownloadOverride(fileRow);
      return;
    }

    let identifier;
    const { fileId } = fileRow;
    if (!fileId) {
      identifier = fileRow.id;
    } else {
      identifier = fileId;
    }
    apiService.getFile(identifier)
      .then((response) => {
        const blob = response.data;
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileRow.filename,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const generateDropDownListItems = (fileRow: FileListFile) => [
    (
      <li key="edit" onClick={() => onClickEditFile(fileRow)}>
        <div className="vertical-aligner">
          <IconContext.Provider value={{ className: 'dropdown-icon' }}>
            <RiPencilFill />
          </IconContext.Provider>
          <span>{translations ? translations.edit : "Edit"}</span>
        </div>
      </li>
    ),
    (
      <li key="delete" onClick={() => onClickRemoveFile(fileRow)}>
        <div className="vertical-aligner">
          <IconContext.Provider value={{ className: 'dropdown-icon' }}>
            <RiDeleteBin7Fill />
          </IconContext.Provider>
          <span>{translations ? translations.delete : "Delete"}</span>
        </div>
      </li>
    ),
  ];

  const createMainColumnGroup = (filesArray: FileListFile[]) => {
    const fileNameColumns = [
      {
        columnKey: 'filename',
        displayName: translations ? translations.name : "Name",
        isSortable: true,
        size: 'column-large',
        onClickColumn: sortByColumn,
      },
    ];
    const fileNameRows = filesArray.map((row) => ([{
      columnKey: 'filename',
      id: row.id,
      rowId: row.id,
      title: row.filename,
      displayValue:
        isReadOnly === true || row.isEditable === false
          ? (
            <div className="vertical-aligner space-between-flex">
              <div>
                <span className={`${getUserTableSpanClass('column-large')} user-table-span-with-icon`}>
                  {row.filename}
                </span>
              </div>

              <div onClick={() => onClickDownloadFile(row)} className='left-icon-group'>
                <IconContext.Provider value={{ className: 'user-table-icon icon-hover' }}>
                  <RiDownload2Line />
                </IconContext.Provider>
              </div>
            </div>
          )
          : (
            <TableCellActionDropDownButton
              onClickRow={() => onClickDownloadFile(row)}
              displayText={row.filename}
              listItems={generateDropDownListItems(row)}
              columnSize="column-large"
            />
          ),
    }]));
    const mainDashboardColumnGroup = createColumnGroup(translations ? translations['class-lang-files'] : "Files", fileNameColumns, fileNameRows);
    return mainDashboardColumnGroup;
  };

  const createFileInfoColumnGroup = (filesArray: FileListFile[]) => {
    const fileInfoColumns = [
      {
        columnKey: 'createddatetime',
        displayName: translations ? translations.created : "Created",
        isSortable: true,
        size: 'column-medium-large',
        onClickColumn: sortByColumn,
      },
    ];

    const fileInfoRows = filesArray.map((row) => ([
      {
        columnKey: 'createddatetime',
        id: `createddatetime-${row.id}`,
        rowId: row.id,
        displayValue: row.createddatetime,
      },
    ]));

    const fileInfoColumnGroup = createColumnGroup('INFO', fileInfoColumns, fileInfoRows);
    return fileInfoColumnGroup;
  };

  useEffect(() => {
    if (files) {
      const allColumnGroups = [];
      const fileCopy = [...files];

      if (currentSortingColumn) {
        fileCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
      }

      const mainColumnGroup = createMainColumnGroup(fileCopy);
      allColumnGroups.push(mainColumnGroup);
      if (isReadOnly !== true) {
        const infoColumnGroup = createFileInfoColumnGroup(fileCopy);
        allColumnGroups.push(infoColumnGroup);
      }

      setColumnGroups(allColumnGroups);
    }
  }, [files, sortedAscending, currentSortingColumn, translations]);

  return (
    <ColumnGroupTable
      columngroups={columnGroups}
      sortedByColumn={currentSortingColumn}
      isSortedAscending={sortedAscending}
      hidePageInputs={hidePageInputs}
      hideFooter={hideFooter}
      translations={translations}
    />
  );
}

export default FileList;
import dayjs from 'dayjs';
import React, { useState, useEffect, FunctionComponent } from 'react';
import { IconContext } from 'react-icons/lib';
import {
  SiMicrosoftteams,
} from 'react-icons/si';
import { CustomerUserTrainingSessionStates, TrainingTypes } from '@murphy-frontend/common/enums';
import { TimeZoneType, getConvertedDate } from '@murphy-frontend/common/services/TimeService';
import { openInNewTab } from '@murphy-frontend/common/utils';
import { SimpleResponsiveListRowModel } from './SimpleResponsiveTable/models';
import { useModal } from '../contexts/ModalContext';
import Button from './Button';
import SimpleResponsiveTable from './SimpleResponsiveTable/SimpleResponsiveTable';

interface UserTrainingSessionsProps {
  translations?: Record<string, string>,
  customerUserTrainingSessions: any[],
  timeZone: TimeZoneType,
  onUnRegisterForTrainingSession: (customerUserTrainingSessionId: any) => void,
}

const UserTrainingSessions: FunctionComponent<UserTrainingSessionsProps> = (
  {
    translations,
    customerUserTrainingSessions,
    timeZone,
    onUnRegisterForTrainingSession,
  }
) => {
  const [completedCourseRows, setCompletedCourseRows] = useState<SimpleResponsiveListRowModel[]>([]);
  const [bookedCourseRows, setBookedCourseRows] = useState<SimpleResponsiveListRowModel[]>([]);
  const [courseColumns, setCourseColumns] = useState([]);

  const [completedExcersiseRows, setCompletedExcersiseRows] = useState<SimpleResponsiveListRowModel[]>([]);
  const [bookedExcersiseRows, setBookedExcersiseRows] = useState<SimpleResponsiveListRowModel[]>([]);

  const { openModal } = useModal();

  const onClickBookedCourseRow = (rowId) => {
    const row = customerUserTrainingSessions.filter((sess) => sess.CustomerUserTrainingSessionId === rowId)[0];
    const courseName = row.TrainingName;
    const urlToMeeting = row.UrlToMeeting;
    const date = getConvertedDate(dayjs.utc(row.Date), timeZone);

    if (row.TrainingSessionStateID === 1) {
      const modalConf = {
        title: `${courseName}`,
        body: <UnRegistrationBody
          date={date}
          urlToMeeting={urlToMeeting}
          onClickUnbook={onUnRegisterForTrainingSession}
          customerUserTrainingSessionId={row.CustomerUserTrainingSessionId}
        />,
        buttonText: translations ? translations.unregister.toUpperCase() : 'Unregister',
        type: "info",
        hideButton: true,
      };

      openModal(modalConf);
    }
  };

  function UnRegistrationBody({
    date, urlToMeeting, onClickUnbook, customerUserTrainingSessionId,
  }) {
    return (
      <div className="file-control">
        <p>
          <b>
            {translations ? translations.datename : 'Date'}
            :
          </b>
          <br />
          {date}
          <br />
          <br />

        </p>
        <br />
        <p>
          <b>{translations ? translations.alreadyregisteredforcoursesession : 'Already registered'}</b>
        </p>

        <div>
          <div className="file-cancel">
            {urlToMeeting && (
              <Button
                onClick={() => openInNewTab(urlToMeeting)}
              >
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                  <SiMicrosoftteams />
                  &nbsp;
                  {translations ? translations.connecttext : 'Connect'}
                </IconContext.Provider>
              </Button>
            )}
          </div>
          <div className="file-submit">
            <Button isWarningButton onClick={() => onClickUnbook(customerUserTrainingSessionId)}>
              <span className="btn-with-icon-text">
                {translations ? translations.unbook : 'Unbook'}
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const getCourseColumns = () => {

    const nameColumnName = translations ? translations.name : 'Name';
    const dateColumnName = translations ? translations.datename : 'Date';

    const newColumns = [
      { key: nameColumnName, title: nameColumnName, columnSize: 2 },
      { key: dateColumnName, title: dateColumnName, columnSize: 1 },
    ];

    return newColumns;
  };

  const mapTrainingRows = (sessions, tz: TimeZoneType): SimpleResponsiveListRowModel[] => sessions.map((training) => {
    const dateColumnName = translations ? translations.datename : 'Date';
    // const statusColumnName = translations['class-lang-modal-status'];
    const courseColumnName = translations ? translations.name : 'Name';

    const row: SimpleResponsiveListRowModel = {
      id: training.CustomerUserTrainingSessionId,
      clickable: true,
      statusid: training.TrainingSessionStateID,
      trainingname: training.TrainingName,
      trainingtypeid: training.TrainingTypeId,
    };

    row[dateColumnName] = getConvertedDate(dayjs.utc(training.Date), tz);
    row[courseColumnName] = training.TrainingName;

    return row;
  });

  useEffect(() => {
    if (customerUserTrainingSessions) {
      const newColumns = getCourseColumns();
      const newRows = mapTrainingRows(customerUserTrainingSessions, timeZone);

      const completedCourses = newRows
        .filter((row) => row.trainingtypeid === TrainingTypes.Course
          && row.statusid === CustomerUserTrainingSessionStates.Completed);
      const bookedCourses = newRows
        .filter((row) => row.trainingtypeid === TrainingTypes.Course
          && row.statusid === CustomerUserTrainingSessionStates.Booked);
      const completedExcercises = newRows
        .filter((row) => row.trainingtypeid === TrainingTypes.Excersise
          && row.statusid === CustomerUserTrainingSessionStates.Completed);
      const bookedExcercises = newRows
        .filter((row) => row.trainingtypeid === TrainingTypes.Excersise
          && row.statusid === CustomerUserTrainingSessionStates.Booked);

      setCourseColumns(newColumns);
      setCompletedCourseRows(completedCourses);
      setBookedCourseRows(bookedCourses);
      setCompletedExcersiseRows(completedExcercises);
      setBookedExcersiseRows(bookedExcercises);
    }
  }, [customerUserTrainingSessions, translations, timeZone]);

  return (
    <>
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            {translations ? translations.performedcourses.toUpperCase() : 'Performed courses'}
          </h4>
        </div>
        <div className="two" />
        <div className="three" />
      </section>
      <section className="container-with-background">
        {completedCourseRows.length === 0 ? (
          <p className="no-items-text">
            {translations ? translations.userhasnocompletedcourses.toUpperCase() : 'User has no completed courses'}
          </p>
        )
          : (
            <SimpleResponsiveTable
              columns={courseColumns}
              rows={completedCourseRows}
            />
          )}
      </section>
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            {translations ? translations.bookedcourses.toUpperCase() : 'Booked courses'}
          </h4>
        </div>
        <div className="two" />
        <div className="three" />
      </section>
      <section className="container-with-background">
        {bookedCourseRows.length === 0 ? (
          <p className="no-items-text">
            {translations ? translations.userhasnobookedcourses.toUpperCase() : 'User has no booked courses'}
          </p>
        )
          : (
            <SimpleResponsiveTable
              onClickRow={onClickBookedCourseRow}
              columns={courseColumns}
              rows={bookedCourseRows}
            />
          )}
      </section>
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            {translations ? translations.performedexcercises.toUpperCase() : 'Performed excercises'}
          </h4>
        </div>
        <div className="two" />
        <div className="three" />
      </section>
      <section className="container-with-background">
        {completedExcersiseRows.length === 0 ? (
          <p className="no-items-text">
            {translations ? translations.noperformedexcercises.toUpperCase() : 'No performed excercises'}
          </p>
        )
          : (
            <SimpleResponsiveTable
              onClickRow={onClickBookedCourseRow}
              columns={courseColumns}
              rows={completedExcersiseRows}
            />
          )}
      </section>
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            {translations ? translations.bookedexcercises.toUpperCase() : 'Booked excercises'}
          </h4>
        </div>
        <div className="two" />
        <div className="three" />
      </section>
      <section className="container-with-background">
        {bookedExcersiseRows.length === 0 ? (
          <p className="no-items-text">
            {translations ? translations.userhasnobookedexcercises.toUpperCase() : 'User has no booked excercises'}
          </p>
        )
          : (
            <SimpleResponsiveTable
              onClickRow={onClickBookedCourseRow}
              columns={courseColumns}
              rows={bookedExcersiseRows}
            />
          )}
      </section>
    </>
  );
}

export default UserTrainingSessions;
import { FunctionComponent, useState } from 'react';
import { isValidWindowsFileName } from '@murphy-frontend/common/validation';
import * as React from 'react';
import Button from './Button';

interface FileUploadControlProps {
  onSubmit: (file: File) => void,
  translations?: Record<string, string>,
  onClose?: () => void
}

const FileUploadControl: FunctionComponent<FileUploadControlProps> = ({
  onSubmit,
  translations,
  onClose }) => {
  const [file, setFile] = useState<File>(null);
  const [fileValidationError, setFileValidationError] = useState('');

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile: File = event.target.files[0];
    const fileSize = newFile.size / 1024 / 1024;
    if (fileSize > 50) {
      setFileValidationError(`Maximum file size ${50} MB.`);
      return;
    }
    const fileName = newFile.name;
    if (!isValidWindowsFileName(fileName)) {
      setFileValidationError('Filename is not a valid filename. Change the filename and upload again');
      return;
    }
    setFileValidationError('');

    setFile(newFile);
  };

  const submitFile = () => {
    setFileValidationError('');
    onSubmit(file);
  }

  return (
    <div className="file-control">
      <div>
        <label className="btn btn--primary btn--medium btn-positive custom-file-upload">
          <input id="fileUploadInput" type="file" onChange={selectFile} />
          {translations ? translations.choosefile : 'Choose file'}
        </label>
      </div>
      {fileValidationError ? <div className="input-error-message">{fileValidationError}</div> : null}
      {file
        ? (
          <div>
            <strong>{file.name}</strong>
          </div>
        ) : null}
      <div>
        <div className="file-cancel">
          <Button buttonStyle="btn--outline" onClick={onClose}>{translations ? translations.cancel : 'Cancel'}</Button>
        </div>
        {file ? (
          <div className="file-submit">
            <Button
              isIconButton
              buttonStyle="btn--upload"
              buttonSize="btn-with-icon"
              onClick={submitFile}>
              <span className="btn-with-icon-text">{translations ? translations.uploadfile : 'Upload file'}</span>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FileUploadControl;

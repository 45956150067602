import React, { FunctionComponent } from 'react';
import {
  TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown,
} from 'react-icons/ti';
import { IconContext } from 'react-icons/lib';
import { getUserTableSpanClass } from './TableUtils';
import { ColumnGroupColumnCell } from './Models';

interface TableColumnCellProps {
  column: ColumnGroupColumnCell,
  isSorted: boolean,
  isSortedAscending: boolean
}

const TableColumnCell: FunctionComponent<TableColumnCellProps> = ({ column, isSorted, isSortedAscending }) => {
  const unsortedIcon = (
    <IconContext.Provider value={{ className: 'user-table-small-icon' }}>
      <TiArrowUnsorted />
    </IconContext.Provider>
  );

  const sortedAscendingIcon = (
    <IconContext.Provider value={{ className: 'user-table-small-icon' }}>
      <TiArrowSortedUp />
    </IconContext.Provider>
  );

  const sortedDescendingIcon = (
    <IconContext.Provider value={{ className: 'user-table-small-icon' }}>
      <TiArrowSortedDown />
    </IconContext.Provider>
  );

  const getSortingIcon = (sorted: boolean, sortedAscending: boolean) => {
    if (sorted && sortedAscending === true) {
      return sortedAscendingIcon;
    }
    if (sorted && sortedAscending === false) {
      return sortedDescendingIcon;
    }

    return unsortedIcon;
  };

  const onClickColumn = () => {
    if (column.isSortable) {
      column.onClickColumn(column.columnKey);
    }
  };

  let headerClass = 'table-column-group-header';

  if (column.size) {
    headerClass += ` ${column.size}`;
  }

  let cellClass = '';
  let sortingIcon = null;
  if (column.isSortable) {
    sortingIcon = getSortingIcon(isSorted, isSortedAscending);
    headerClass += ' clickable-cell vertical-space-flex';
  }

  if (column.isSortable) {
    cellClass += `${getUserTableSpanClass(column.size)} user-table-span-with-icon`;
  }
  return (
    <div onClick={onClickColumn} className={headerClass}>
      <span className={cellClass}>
        {column.displayName}
      </span>

      {column.isSortable ? sortingIcon : null}
    </div>
  );
}

export default TableColumnCell;

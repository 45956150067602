import React, { FunctionComponent } from 'react';
import { ColumnGroupRowCell } from './Models';
import TableRowCell from './TableRowCell';

interface TableColumnGroupRowProps {
  cells: ColumnGroupRowCell[]
}

const TableColumnGroupRow: FunctionComponent<TableColumnGroupRowProps> = ({ cells }) => {
  return (
    <div>
      {cells.map((cell) => (<TableRowCell key={`${cell.columnKey}-${cell.rowId}`} rowCell={cell}>
        {cell.displayValue}
      </TableRowCell>))}
    </div>
  );
}

export default TableColumnGroupRow;

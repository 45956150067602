import { Constant } from './models/Constant';

export const screenSizes = [
  { id: 'xs', value: 576 },
  { id: 'sm', value: 768 },
  { id: 'md', value: 992 },
  { id: 'lg', value: 1200 },
  { id: 'xl', value: 1800 },
] as Constant[];

export const statusTypes =
  [
    {
      id: 1, value: 'Default', valueSwe: 'Standard', valueEng: 'Default', valueNor: 'Standard', valueFin: 'Vakio',
    },
    {
      id: 2, value: 'MSB', valueSwe: 'MSB', valueEng: 'MSB', valueNor: 'MSB', valueFin: 'MSB',
    },
  ] as Constant[];

export const timeZones =
  [
    {
      id: 1, value: 'Local', valueSwe: 'Lokal tid', valueEng: 'Local time', valueNor: 'Lokal tid', valueFin: 'Paikallinen aika',
    },
    {
      id: 2, value: 'UTC', valueSwe: 'UTC', valueEng: 'UTC', valueNor: 'UTC',
    },
  ] as Constant[];

export const languages =
  [
    { id: 'swe', value: 'Svenska' },
    { id: 'eng', value: 'English' },
    { id: 'nor', value: 'Norsk' },
    { id: 'fin', value: 'Suomeksi' },
  ] as Constant[];

export const courseLanguages =
  [
    { id: 'swe', value: 'Svenska' },
    { id: 'eng', value: 'English' },
  ] as Constant[];

export const roles =
  [
    { id: 0, value: 'User' },
    { id: 1, value: 'Admin' },
    { id: 2, value: 'ReadOnly' },
    { id: 3, value: 'Trial' },
  ] as Constant[];

export const dashboardTypes =
  [
    {
      id: 1, value: 'Standard', valueSwe: 'Öppen', valueEng: 'Open', valueNor: 'Åpen', valueFin: 'Avoin',
    },
    {
      id: 2, value: 'Explicit', valueSwe: 'Begränsad', valueEng: 'Restricted', valueNor: 'Begrenset', valueFin: 'Rajoitettu',
    },
    // {
    //   id: 3, value: 'Read Only', valueSwe: 'Läs', valueEng: 'Read Only', valueNor: 'Les',
    // },
  ] as Constant[];

export const products =
  [
    {
      id: 1, value: 'Alert', translationKey: 'mbsmodulename',
    },
    {
      id: 2, value: 'Operations', translationKey: 'whitemodulename',
    },
    {
      id: 3, value: 'Courses', translationKey: 'coursesmodulename',
    },
    {
      id: 4, value: 'Guidance', translationKey: 'guidance',
    },
    {
      id: 5, value: 'Training', translationKey: 'excercisesmodulename',
    },
    {
      id: 6, value: 'Murphy Standard', translationKey: 'pyramidmodulename',
    },
    {
      id: 7, value: 'Event Flows', translationKey: 'eventflowsmodulename',
    },
    {
      id: 8, value: 'ElearningSwe', translationKey: 'elearningswemodulename',
    },
    {
      id: 9, value: 'ElearningFtg', translationKey: 'elearningftgmodulename',
    },
  ] as Constant[];

export const customerUserTrainingSessionStates =
  [
    {
      id: 1, value: 'Booked', valueSwe: 'Bokad', valueEng: 'Booked', valueNor: 'Booket', valueFin: 'Varattu',
    },
    {
      id: 2, value: 'Cancelled', valueSwe: 'Avbokad', valueEng: 'Un-booked', valueNor: 'Av-booket', valueFin: 'Peruutettu',
    },
    {
      id: 3, value: 'Completed', valueSwe: 'Genomförd', valueEng: 'Finished', valueNor: 'Gjenomført', valueFin: 'Suoritettu',
    },
    {
      id: 4, value: 'Not attended', valueSwe: 'Närvarade ej', valueEng: 'Did not attend', valueNor: 'Ikke møtte', valueFin: 'Ei osallistunut',
    },
  ] as Constant[];

export const educators =
  [
    {
      id: 'Inget val',
      value: 'Inget val',
    },
    {
      id: 'Mats',
      value: 'Mats',
    },
    {
      id: 'Andreas',
      value: 'Andreas',
    },
    {
      id: 'Elin',
      value: 'Elin',
    },
    {
      id: 'Mikael',
      value: 'Mikael',
    },
    {
      id: 'Adam',
      value: 'Adam',
    },
    {
      id: 'Jan',
      value: 'Jan',
    },
    {
      id: 'Johanna',
      value: 'Johanna',
    },
    {
      id: 'Robin',
      value: 'Robin',
    },
    {
      id: 'Tobias',
      value: 'Tobias',
    },
    {
      id: 'Craig',
      value: 'Craig',
    },
  ] as Constant[];

export const RequirementCategories = [{
  id: 1,
  value: "People",
  translationKey: 'reqcategorypeople',
}, {
  id: 2,
  value: "Organisation",
  translationKey: 'reqcategoryorganisation',
}, {
  id: 3,
  value: "Plans",
  translationKey: 'reqcategoryplans',
},
{
  id: 4,
  value: "Documents",
  translationKey: 'reqcategorydocuments',
},
{
  id: 5,
  value: "Communication",
  translationKey: 'reqcategorycommunication',
}] as Constant[];


export const DefaultCustomerFileTypes = [
  //   Crisis Management
  // Continuity Planning
  // Risk Analysis
  // Incident Response
  {
    id: 1,
    value: "Operations",
  }, {
    id: 2,
    value: "Continuity Planning"
  }, {
    id: 3,
    value: "Risk Analysis"
  },
  {
    id: 4,
    value: "Incident Response"
  },
] as Constant[];

export const RequirementStandards = [{
  id: 1,
  value: "Murphy Standard"
}] as Constant[];

export const TrainingLanguages = [{
  id: 1,
  value: "swe"
}, {
  id: 2,
  value: "eng"
}] as Constant[];

export const DbLanguages = [{
  id: 1,
  value: "eng"
}, {
  id: 2,
  value: "swe"
}, {
  id: 3,
  value: "nor"
}, {
  id: 4,
  value: "fin"
},
  // {
  //   id: 5,
  //   value: "dan"
  // }
] as Constant[];
import { useState, useEffect } from 'react';

const useTruncatedText = (
    ref: React.RefObject<HTMLElement>,
    text: string,
    maxHeight: number
): string => {
    const [truncatedText, setTruncatedText] = useState(text);

    useEffect(() => {
        if (!ref.current) return;

        const computeTruncatedText = () => {
            const element = ref.current;
            const tempEl = document.createElement('div');
            tempEl.style.visibility = 'hidden';
            tempEl.style.position = 'absolute';
            tempEl.style.width = `${element.offsetWidth}px`;
            tempEl.style.font = getComputedStyle(element).font;
            tempEl.style.whiteSpace = 'pre-wrap';
            tempEl.style.wordWrap = 'break-word';
            document.body.appendChild(tempEl);

            let currentText = text;
            tempEl.innerText = currentText;

            while (tempEl.scrollHeight > maxHeight && currentText.length > 0) {
                let lastSpaceIndex = currentText.lastIndexOf(' ');
                if (lastSpaceIndex === -1) break;
                currentText = currentText.slice(0, lastSpaceIndex) + '..';
                tempEl.innerText = currentText;
            }

            setTruncatedText(currentText);
            document.body.removeChild(tempEl);
        };

        computeTruncatedText();
    }, [ref, text, maxHeight]);

    return truncatedText;
};

export default useTruncatedText;

import React, { ChangeEventHandler, FunctionComponent, useRef } from 'react';
import { MdEdit, MdOutlineSave } from 'react-icons/md';
import { FaUndoAlt } from 'react-icons/fa';
import { CgUndo } from 'react-icons/cg';
import { IconContext } from 'react-icons/lib';
import { useClickAway } from '@uidotdev/usehooks';

interface InputProps {
  title?: React.ReactNode,
  val?: string | number | readonly string[],
  placeholder?: string,
  type?: React.HTMLInputTypeAttribute,
  name?: string,
  isRequired?: boolean,
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  errorMessage?: string,
  infoMessages?: string[],
  direction?: string,
  defaultChecked?: boolean,
  disabled?: boolean,
  showEdit?: boolean,
  onClickUndo?: () => void,
  onClickSave?: () => void,
  maxLength?: number,
  min?: string | number,
  max?: string | number,
  register?: any,
  defaultValue?: string | number | readonly string[],
  isControlled?: boolean,
  width?: 'small' | 'medium' | 'large' | '100',
}

const Input: FunctionComponent<InputProps> = ({
  title, val, placeholder, type, name, isRequired,
  handleChange, errorMessage, infoMessages, direction, defaultChecked,
  disabled, showEdit, onClickSave, onClickUndo, maxLength, min, max,
  register, defaultValue, isControlled, width
}) => {

  const [isEditing, setIsEditing] = React.useState(false);
  const ref = useClickAway(() => {
    setIsEditing(false);
    onClickUndo?.();
  });

  const handleClickSave = () => {
    setIsEditing(false);
    onClickSave();
  }

  const handleClickEdit = () => {
    setIsEditing(true);
  }

  const handleClickUndo = () => {
    setIsEditing(false);
    onClickUndo?.();
  }

  const id = `input-${new Date().getTime()}`;
  let classes = 'form-input';
  if (direction === 'column') {
    classes = ` ${'form-input-column-flow'}`;
  }
  if (showEdit == true && isEditing == false) {
    classes += ` ${'form-input-editable'}`;
  }
  if (width === 'small') {
    classes += ` ${'form-input-small'}`;
  }
  if (width === 'medium') {
    classes += ` ${'form-input-medium'}`;
  }
  if (width === 'large') {
    classes += ` ${'form-input-large'}`;
  }
  if (width === '100') {
    classes += ` ${'form-input-100percent'}`;
  }
  // if (isEditing == true) {
  //   classes += ` ${'form-input-editing'}`;
  // }


  return (
    (<div className={classes} ref={ref}>
      {title ? <label htmlFor={id}>{title}</label> : null}
      {isControlled === true ? (
        <input
          onClick={handleClickEdit}
          id={id}
          onChange={handleChange}
          required={isRequired}
          autoComplete="off"
          placeholder={placeholder}
          type={type}
          name={name}
          defaultValue={defaultValue}
          {...register(name)}
          defaultChecked={defaultChecked}
          disabled={disabled}
          maxLength={maxLength}
          min={min}
          max={max}
        />
      ) : (
        <input
          onClick={handleClickEdit}
          id={id}
          value={val}
          onChange={handleChange}
          required={isRequired}
          autoComplete="off"
          placeholder={placeholder}
          type={type}
          name={name}
          defaultValue={defaultValue}
          defaultChecked={defaultChecked}
          disabled={disabled}
          maxLength={maxLength}
          min={min}
          max={max}
        />
      )}
      {showEdit ? (
        (isEditing ? <div className='searchbar-inner-icon-container'>

          <IconContext.Provider value={{ className: 'inner-icon' }}>
            <CgUndo onClick={handleClickUndo} />
          </IconContext.Provider>
          <IconContext.Provider value={{ className: 'inner-icon' }}>
            <MdOutlineSave onClick={handleClickSave} />
          </IconContext.Provider>
        </div> : null)
        // <div className='searchbar-inner-icon-container'>
        //   <IconContext.Provider value={{ className: 'inner-icon' }}>
        //     <MdEdit onClick={handleClickEdit} />
        //   </IconContext.Provider>
        // </div>
      ) : null}
      {errorMessage ? <div className="input-error-message">{errorMessage}</div> : null}
      {infoMessages ? infoMessages.map((p) => <div key={p} className="input-info-message">{p}</div>) : null}
    </div>)
  );
}

export default Input;
